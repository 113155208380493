import { useEffect, useState } from "react";
import { Task, User as UserType } from "../../types";
import { Link } from "react-router-dom";
import TasksContoller from "../../api/admin/tasks";
import { toast } from "react-toastify";
import Sub from "../../components/admin/tasks/Sub";
import Mystery from "../../components/admin/tasks/Mystery";
import UsersContoller from "../../api/admin/users";
import FarmInviteVisitChangeContainer from "../../components/admin/tasks/FarmInviteVisitForm";

export default function InactiveSubs() {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [users, setUsers] = useState<UserType[]>([]);

    const getTasks = async () => {
        const response = await TasksContoller.getTasks();

        if (response.error) return toast(response.message);

        setTasks(response.tasks);
    }

    const getUsers = async () => {
        const response = await UsersContoller.getUsers();

        if (response.error) return toast(response.message);

        setUsers(response.users);
    }

    useEffect(() => {
        getTasks();
        getUsers();
    }, []);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="up-part-of-dinamic">
                <Link to="/admp/tasks" className="btn-s-gradient">
                    <span>&lt; Назад</span>
                </Link>
            </div>
            <div className="dinamic-content -cmr-users -other-pages-content">

                <div className="additional-wrap-of-crm">
                    <div className="current-data">
                        <span>Найдено:</span>
                        <span className="-yellow-info">{tasks.filter(task => !task.active && task.key !== "sub" && task.key !== "mystery").length}</span>
                    </div>
                </div>

                <div className="list-of-profits">
                    {tasks.filter(task => task.key === "sub" && !task.active).map(task => {
                        return <Sub key={task.uuid} task={task} users={users} setTasks={setTasks} />
                    })}

                    {tasks.filter(task => task.key === "mystery" && !task.active).map(task => {
                        return <Mystery key={task.uuid} task={task} users={users} setTasks={setTasks} />
                    })}

                    {tasks.filter(task => (task.key === "farm" || task.key === "invite" || task.key === "visit") && !task.active).map((task, index) => (
                        <FarmInviteVisitChangeContainer task={task} index={index} key={task.uuid} setTasks={setTasks} />
                    ))}
                </div>

                {/* <FilterByName users={users} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers} allUsers={allUsers} /> */}

                {/* <div className="list-of-users">
                    {paginatedUsers.map(user => {
                        return <User user={user} key={user.tg_id} setAllUsers={setAllUsers} />
                    })}
                </div>

                <Pagination filteredUsers={filteredUsers} setPaginatedUsers={setPaginatedUsers} /> */}
            </div>
        </div>
    </section>
}