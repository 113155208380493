import { useCallback, useEffect, useState } from "react";
import { AutoRewardType } from "../types";
import { useUser } from "../context/UserContext";
import { useError } from "../context/ErrorContext";
import AutoRewardController from "../api/autoreward";

export default function useAutoReward() {
    const { tg_id, setUser } = useUser();
    const { setError } = useError();

    const [autoRewardStarted, setAutoRewardStarted] = useState<AutoRewardType | null>(null);

    const getAutoRewardStatus = useCallback(async (tg_id: string) => {
        const response = await AutoRewardController.statusAutoReward(tg_id);

        if (response.error) return setError(response.message);

        setAutoRewardStarted(response.status);

        if (response.user) setUser(response.user);
    }, [setError, setUser]);

    useEffect(() => {
        if (!tg_id) return;

        getAutoRewardStatus(tg_id);
    }, [tg_id, setError, setAutoRewardStarted, getAutoRewardStatus]);

    return {
        autoRewardStarted, setAutoRewardStarted, getAutoRewardStatus
    }
};