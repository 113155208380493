
import Portal from "./Portal";
import PortalBack from "./Portal-Back";

export default function Body() {
    return <div className="main-part">
        <div className="up-of-inner-main-part">
            <div className="main-header">
                Собери мухоморы
            </div>
        </div>
        <div className="inner-main-part -game-colums">
            <div className="game-dynamic-up-part">
                {[1, 2, 3].map(idx => {
                    return <Portal key={idx} idx={idx} />
                })}
            </div>

            <PortalBack />
        </div>
    </div>
}