import AboutProject from "../modal/AboutProject";
import ActiveTab from "../tabs/ActiveTab";
import Tabs from "../tabs/Buttons";
import ContainerHeader from "./Header";
import { useUser } from "../../../context/UserContext";
import SubscriptionModal from "../modal/Subscription";
import OnlineTimer from "../OnlineTime";
import Preloader from "../Preloader";
import LevelDecksModal from "../modal/LevelDecks";
import Airdrop from "../modal/Airdrop";
import Top100 from "../modal/Top100";
import Friends from "../modal/Friends";
import Invite from "../modal/Invite";
import GameContainer from "../ducks/game/Container";

export default function Body() {
    const { user, loading, startGame } = useUser();

    if (!user) return null;

    return <div className="app-part">
        {loading && <Preloader />}

        {!startGame ?
            <div className={`inner-app-part start-display -duck-level${user.level.user_level}`}>
                <ContainerHeader user={user} />

                <div className="main-part">
                    <AboutProject />

                    <LevelDecksModal />

                    <Airdrop />

                    <Top100 />

                    <Friends />

                    <Invite />

                    {/* <div className="bg-item"></div> */}

                    <ActiveTab />

                    <Tabs />

                    <SubscriptionModal />
                </div>
            </div>

            : <GameContainer />}

        <OnlineTimer userId={user.tg_id} />
    </div>
}