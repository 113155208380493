import { useState, useEffect } from 'react';
import UserController from '../../api/user';

const OnlineTimer = ({ userId }: { userId: string }) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 5);

            if (seconds > 0) {
                UserController.increaseSpentTime(userId, 5);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [userId, seconds]);

    return null;
};

export default OnlineTimer;
