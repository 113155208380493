import http from "./index";

const signIn = async (login: string, password: string) => {
    try {
        const { data } = await http.post("/admin/login", {
            login,
            password,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const signOut = async () => {
    try {
        const { data } = await http.post("/admin/logout");
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const checkAuth = async () => {
    try {
        const { data } = await http.get("/admin/me");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const AdminController = {
    signIn,
    signOut,
    checkAuth,
};

export default AdminController;