import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TasksContoller from "../../api/admin/tasks";

export default function CreateFarmInviteVisit() {
    const { taskKey } = useParams();
    const [rewardMushrooms, setRewardMushrooms] = useState<string>("");
    const [rewardTickets, setRewardTickets] = useState<string>("");
    const [quantity, setQuantity] = useState<string>("");

    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!taskKey) return;

        const response = await TasksContoller.createFarmInviteVisitTask({
            key: taskKey,
            rewardMushrooms,
            rewardTickets,
            quantity,
        });

        if (response.error) return toast(response.message);

        return toast(response.message);
    }, [taskKey, quantity, rewardMushrooms, rewardTickets]);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="up-part-of-dinamic">
                <Link to={`/admp/tasks/change/farminvitevisit/${taskKey}`} className="btn-s-gradient">
                    <span>&lt; Назад</span>
                </Link>
            </div>
            <div className="main-header-of-page">
                Добавить задание: {taskKey === "farm" ? "фарм мухоморов🍄 в игре" : taskKey === "invite" ? "пригласить игроков" : "📲 зашел в игру дней подряд"}
            </div>

            <div className="dinamic-content -cmr-users -other-pages-content ">
                <form className="-profile-setting mt40" onSubmit={handleSubmit}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="input-wrap  -valid-input">
                                <div className="header-of-input">
                                    {taskKey === "farm" ? "🕹 Количество зафармленных мухоморов🍄 в игре" : taskKey === "invite" ? "👥 Количество приглашенных" : "📲 Зашел в игру подряд дней"}
                                </div>
                                <input type="text" value={quantity} onChange={event => setQuantity(event.target.value)} />
                                <div className="error-input">
                                    Вы допустили ошибку
                                </div>
                            </div>
                            <div className="input-wrap  -valid-input">
                                <div className="header-of-input">
                                    Даем мухоморов🍄
                                </div>
                                <input type="text" value={rewardMushrooms} onChange={event => setRewardMushrooms(event.target.value)} />
                                <div className="error-input">
                                    Вы допустили ошибку
                                </div>
                            </div>
                            <div className="input-wrap  -valid-input">
                                <div className="header-of-input">
                                    Даем билетов🎟
                                </div>
                                <input type="text" value={rewardTickets} onChange={event => setRewardTickets(event.target.value)} />
                                <div className="error-input">
                                    Вы допустили ошибку
                                </div>
                            </div>
                            <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Добавить</button>
                        </div>
                    </div>
                </form>
            </div>


        </div>
    </section>
}