import { createContext, useContext, useEffect, useState } from "react";
import { LevelsContextType, LevelsType } from "../types";
import LevelsController from "../api/levels";
import { useError } from "./ErrorContext";

export const LevelsContext = createContext<LevelsContextType | undefined>(undefined);

export function LevelsProvider({ children }: { children: React.ReactNode }) {
    const [levels, setLevels] = useState<LevelsType | null>(null);
    const { setError } = useError();

    useEffect(() => {

        const getLevels = async () => {
            const response = await LevelsController.getLevels();

            if (response.error) return setError(response.message);

            setLevels(response.levels);
        };

        getLevels();
    }, [setError]);

    return <LevelsContext.Provider value={{ levels }}>{children}</LevelsContext.Provider>;
}

export const useLevels = () => {
    const context = useContext(LevelsContext);

    if (!context) {
        throw new Error("useTabs must be used within a GameProvider");
    }
    return context;
};