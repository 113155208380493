import { useEffect } from "react";

export function usePreventCollapse() {
    useEffect(() => {
        function preventCollapse() {
            if (window.scrollY === 0) {
                window.scrollTo(0, 1);
            }
        }

        const scrollableElements = document.querySelectorAll('.-aifs, .inner-part-of-modal, .inner-list-players');

        scrollableElements.forEach(element => {
            element.addEventListener("touchstart", preventCollapse);
        });

        return () => {
            scrollableElements.forEach(element => {
                element.removeEventListener("touchstart", preventCollapse);
            });
        };
    }, []);
}