import { useTabs } from "../../../context/TabsContext";
import ton_duck from "../../../assets/img/ducks/ton-duck.png";
import ton_coin from "../../../assets/img/modal/ton-coin.png";
import throne_duck from "../../../assets/img/ducks/throne-duck.png";
import { useEffect, useState } from "react";
import { useUser } from "../../../context/UserContext";
import UserController from "../../../api/user";
import Input from "../../admin/tasks/inputs/Input";

export default function Airdrop() {
    const { activeTab, setActiveTab } = useTabs();
    const { user } = useUser();
    const [wallet, setWallet] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (user) {
            setWallet(user.wallet || "");
        }
    }, [user]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!user) return;

        const response = await UserController.setWallet(user.tg_id, wallet);

        if (response.error) {
            return setMessage(response.message);
        }

        setMessage("");
    }

    if (!user) return null;

    return <div className={`modal-window -task -airdrop ${activeTab === "airdrop" ? "-open" : ""}`}>
        <div className="close-modal -svg" onClick={() => { setActiveTab("ducks") }}>
            <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg>
        </div>
        <div className="up-part-of-modal">
            <div className="left-part">
                <div className="alert-of-modal">AirDrop</div>
                <div className="modal-header">
                    Добавь свой<br />кошелек TON
                </div>
                <div className="alert-of-modal-2">
                    И учавствуй в предварительной раздаче токенов
                </div>
            </div>
            <div className="right-part">
                <img src={ton_duck} alt="" />
            </div>

        </div>
        <form className="down-part-of-modal -with-input" onSubmit={handleSubmit}>
            <Input title="" state={wallet} setState={setWallet} placeholder="Адрес кошелька TON" />

            {message && <div className="err" style={{ "margin": "-10px 0 15px" }}>{message}</div>}

            <div className="contest-wrap">
                <div className="block-info -aic">
                    <div className="left-part">
                        <div className="decor-duck"><img src={throne_duck} alt="" /></div>
                    </div>
                    <div className="right-part">
                        <div className="contest-prize">
                            <span>+</span>
                            <div className="ton-icon -w14"></div>
                            <span>+</span>
                            <div className="duck-coin -w14"></div>
                        </div>
                        <button className="btn-action" onClick={() => { }}>
                            <div className="signature-btn-action">
                                Конкурсы
                            </div>
                        </button>
                    </div>

                </div>
                <button className="modal-btn -start-game" type="submit">
                    <span>Сохранить</span>
                </button>
            </div>
        </form>
        <div className="inner-part-of-modal">
            {user.wallet && <div className="amoult-info -fz14 -modal-mb20" >
                <div className="signature-for-amoult-info">
                    Статус кошелька:
                </div>
                <div className="value-for-amoult-info">
                    Добавлен игроком
                </div>
            </div>}
            <div className="main-signature -modal-mb20">
                Наш проект стремительно развивается и с каждым новым количеством игроков — мы проводим выборочную раздачу токенов самым активным игрокам Duck Valhalla!
            </div>
            <div className="modal-image-wrap">
                <img src={ton_coin} alt="" />
            </div>
        </div>
    </div>
}