import { Outlet } from "react-router-dom";
import { AdminProvider } from "../../context/AdminContext";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function AdminLayout() {
    return (
        <HelmetProvider>
            <AdminProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bot CRM</title>
                    <meta name="viewport" content="width=device-width, initial-scale=0.88, maximum-scale=0.88, user-scalable=0" />
                    <link href="/assets/admin.css" rel="stylesheet" />
                </Helmet>
                <Outlet />
            </AdminProvider>
        </HelmetProvider>
    );
}
