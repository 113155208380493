import { useTabs } from "../../../context/TabsContext";
import { TabsType } from "../../../types";
import duck_head from "../../../assets/img/duck-head.png";
import { useTasks } from "../../../context/TasksContext";

interface Props {
    name: TabsType;
    children: React.ReactNode
}

function Tab({ name, children }: Props) {
    const { activeTab, setActiveTab } = useTabs();

    return <div className={"nav-button" + (activeTab === name ? " active" : "")} onClick={() => setActiveTab(name)}>
        {children}
    </div>
}

export default function Tabs() {
    const { tasks } = useTasks();

    if (!tasks) return null;

    return <div className="nav-buttons">
        <Tab name="tasks">
            <div className="task -svg">
                <div className="amoult-of-task">{tasks.filter(task => !task.parentTaskUuid).length || 0}</div>
                <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                    <path className="cls-1" d="M302.72,0H123.95C46.29,0,0,46.29,0,123.95v178.77c0,77.65,46.29,123.95,123.95,123.95h178.77c77.65,0,123.95-46.29,123.95-123.95V123.95C426.67,46.29,380.37,0,302.72,0h0ZM170.03,275.2l-48,48c-3.2,3.2-7.25,4.69-11.31,4.69s-8.32-1.49-11.31-4.69l-16-16c-6.4-6.19-6.4-16.43,0-22.61,6.19-6.19,16.21-6.19,22.61,0l4.69,4.69,36.69-36.69c6.19-6.19,16.21-6.19,22.61,0,6.19,6.19,6.19,16.43,0,22.61ZM170.03,125.87l-48,48c-3.2,3.2-7.25,4.69-11.31,4.69s-8.32-1.49-11.31-4.69l-16-16c-6.4-6.19-6.4-16.43,0-22.61,6.19-6.19,16.21-6.19,22.61,0l4.69,4.69,36.69-36.69c6.19-6.19,16.21-6.19,22.61,0,6.19,6.19,6.19,16.43,0,22.61h0ZM331.95,311.89h-112c-8.75,0-16-7.25-16-16s7.25-16,16-16h112c8.96,0,16,7.25,16,16s-7.04,16-16,16ZM331.95,162.56h-112c-8.75,0-16-7.25-16-16s7.25-16,16-16h112c8.96,0,16,7.25,16,16s-7.04,16-16,16Z"></path>
                </svg>
            </div>
            <span>Задания</span>
        </Tab>

        <Tab name="ducks">
            <div className="playicon -svg">
                <img src={duck_head} alt="" />
            </div>
            <span>Игра</span>
        </Tab>

        <Tab name="players">
            <div className="friends -svg">
                <svg id="layer_1" data-name="layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 361.23">

                    <path className="cls-1" d="M314.34,149.39c41.19,0,74.69-33.51,74.69-74.7S355.53,0,314.34,0s-74.7,33.51-74.7,74.7,33.52,74.7,74.7,74.7Z"></path>
                    <path className="cls-1" d="M314.41,173.39c-15.06,0-29.47,2.41-42.83,7.17-8.31,2.96-12.66,12.12-9.7,20.44,2.71,7.6,10.55,11.58,18.26,10.02.73-.15,1.47-.06,2.19-.31,9.89-3.52,20.69-5.31,32.08-5.31,27.19,0,52.72,10.55,71.88,29.71,19.16,19.16,29.72,44.7,29.72,71.89,0,5.95-2.31,11.52-6.52,15.73-4.2,4.2-9.72,6.51-15.56,6.51h-86.08c-1.13,0-2.11.42-3.17.64-7.26,1.51-12.83,7.65-12.83,15.36,0,8.84,7.16,16,16,16h86.08c14.39,0,27.95-5.65,38.19-15.88,10.25-10.24,15.89-23.86,15.89-38.36,0-35.74-13.89-69.3-39.09-94.52-25.2-25.2-58.77-39.09-94.5-39.09Z"></path>
                    <path className="cls-1" d="M133.66,149.39c41.19,0,74.7-33.51,74.7-74.7S174.84,0,133.66,0,58.97,33.51,58.97,74.7s33.5,74.7,74.69,74.7Z"></path>
                    <path className="cls-1" d="M54.12,361.18h159.08c29.84,0,54.12-24.28,54.12-54.12,0-73.7-59.97-133.66-133.67-133.66S0,233.35,0,307.05c0,29.84,24.28,54.12,54.12,54.12Z"></path>
                </svg>
            </div>
            <span>Игроки</span>
        </Tab>
        <Tab name="airdrop">
            <div className="nav-button -open-airdrop">
                <div className="airdrop -svg">
                    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459.2 512">
                        <path className="cls-1" d="M459.16,203.33c.02-.3.03-.61.04-.91,0-90.55-67.79-167.41-160.98-193.19,8.86,12.19,16.69,27.14,23.36,44.65,13.14,34.5,21.63,79.2,24.03,125.31,8.19-3.06,17.46-4.79,27.13-4.79,21.96,0,41.81,8.86,51.76,22.14l-154.3,91.58,43.54-77.35c1.66-2.43,2.56-5.46,2.56-8.47C315.56,104.44,285.09.3,230.07,0c-.1,0-.23,0-.32,0-55.12.12-85.63,104.32-86.37,202.31,0,2.99.89,5.99,2.51,8.4l43.68,78.12L34.28,197.85c9.63-13.88,30.18-23.44,52.66-23.44,9.68,0,18.94,1.73,27.13,4.79,2.4-46.18,10.91-90.92,24.07-125.44,6.66-17.47,14.49-32.39,23.33-44.55-34.83,9.68-66.84,26.78-93.55,50.44-21.19,18.76-37.87,40.59-49.56,64.88C6.27,149.62.11,176.16.01,203.42c-.26,6.18,3.39,11.98,8.94,14.38l148.29,86.87h143.89l149.18-88.54c5.03-2.24,8.51-7.13,8.85-12.79h0ZM175.04,201.34c8.41-15.89,30.29-26.93,54.8-26.93s46.37,11.02,54.79,26.9l-54.99,97.69-54.61-97.67Z"></path>
                        <g>
                            <path className="cls-1" d="M244.44,391.09v-56.42h-29.68v56.67l15.14-10.07,14.53,9.82Z"></path>
                            <path className="cls-1" d="M345.97,334.67h-71.52v84.19c0,5.28-2.53,10.34-7,13.16-5.04,3.18-11.48,3.07-16.41-.26l-21.27-14.37-21.7,14.43c-5.33,3.55-12.43,3.36-17.63-.73-3.7-2.91-5.69-7.49-5.69-12.19v-84.24h-71.52c-8.29,0-15,6.72-15,15v147.32c0,8.29,6.72,15,15,15h232.74c8.29,0,15-6.72,15-15v-147.32c0-8.29-6.72-15-15-15h0Z"></path>
                        </g>
                    </svg>
                </div>
                <span>AirDrop</span>
            </div>
        </Tab>
    </div>
}