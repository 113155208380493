import { useEffect, useState } from 'react';
import { User } from '../../types';

const Pagination = ({ filteredUsers, setPaginatedUsers }: { filteredUsers: User[], setPaginatedUsers: React.Dispatch<React.SetStateAction<User[]>> }) => {
    const usersPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    useEffect(() => {
        setPaginatedUsers(filteredUsers.slice(0, usersPerPage));
    }, [filteredUsers, setPaginatedUsers]);

    useEffect(() => {
        setPaginatedUsers(filteredUsers.slice(currentPage * usersPerPage - usersPerPage, currentPage * usersPerPage));
    }, [filteredUsers, setPaginatedUsers, currentPage]);

    const handleClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handlePrev = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    return (
        <ul className="pagination-of-users">
            <li>
                <button className="btn-s-users" onClick={handlePrev} disabled={currentPage === 1}>
                    &lt;
                </button>
            </li>
            {getPageNumbers().map((number) => (
                <li key={number}>
                    <button
                        className={`btn-s-users ${number === currentPage ? 'active' : ''}`}
                        onClick={() => handleClick(number)}
                    >
                        {number}
                    </button>
                </li>
            ))}
            <li>
                <button className="btn-s-users" onClick={handleNext} disabled={currentPage === totalPages}>
                    &gt;
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
