import { useState, useRef, useEffect } from "react";

interface UseFileUploadResult {
    file: File | null;
    handleFileClick: () => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    removeFile: () => void;
}

const urlToFile = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const mimeType = blob.type;
    const extension = mimeType.split('/')[1]; // Получаем расширение из MIME-типа
    const filename = `file.${extension}`; // Создаем базовое имя файла

    return new File([blob], filename, { type: mimeType });
};

const useFileUpload = (initialImageUrl?: string): UseFileUploadResult => {
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const updateFileRef = useRef<boolean>(false);

    useEffect(() => {
        if (initialImageUrl) {
            const fetchInitialFile = async () => {
                const initialFile = await urlToFile("/images/" + initialImageUrl);
                setFile(initialFile);
                updateFileRef.current = true;
            };

            if(!updateFileRef.current) {
                fetchInitialFile();
            }
        }
    }, [initialImageUrl]);

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Программный клик на input file
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };

    const removeFile = () => {
        setFile(null);
    };

    return {
        file,
        handleFileClick,
        handleFileChange,
        fileInputRef,
        removeFile
    };
};

export default useFileUpload;
