import { http } from ".";
import { AutoRewardResponseType, ErrorResponseType } from "../types";

const statusAutoReward = async (id: string): Promise<AutoRewardResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/autoreward/status/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const AutoRewardController = {
    statusAutoReward,
};

export default AutoRewardController;