import { useEffect } from "react";
import Container from "../components/game/container/Container";
import { ErrorProvider } from "../context/ErrorContext";
import { LevelsProvider } from "../context/LevelsContext";
import { TabsProvider } from "../context/TabsContext";
import { TasksProvider } from "../context/TasksContext";
import { UserProvider } from "../context/UserContext";
import { useLocation } from "react-router-dom";

export default function Game() {
    const location = useLocation();

    useEffect(() => {
        if (location && location.pathname === "/" && location.search === "") {
            // @ts-ignore
            const userId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;

            if (userId) {
                window.location.href = `${process.env.REACT_APP_SERVER_URL}/?id=${userId}`;
            }
        }
    }, [location]);

    return <>
        <ErrorProvider>
            <UserProvider>
                <LevelsProvider>
                    <TasksProvider>
                        <TabsProvider>
                            <Container></Container>
                        </TabsProvider>
                    </TasksProvider>
                </LevelsProvider>
            </UserProvider>
        </ErrorProvider>
    </>
}
