import { http } from ".";
import { ErrorResponseType, LevelsResponseType } from "../types";

const getLevels = async (): Promise<LevelsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/levels`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const LevelsController = {
    getLevels,
};

export default LevelsController;