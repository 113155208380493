import { Task } from "../../../../types";
import Status from "./layout/Status";

interface Props {
    task: Task;
}

export default function Subscription({ task }: Props) {
    return <div className={`block-info -fdc -subscribe ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <img src={`/images/${task.icon}`} alt="subscription" />
            </div>
            <div className="main-txt -fz14">
                Подпишись на канал <br /> <span>{task.desc}</span>
            </div>
        </div>

        <Status reward={{ mushrooms: task.rewardMushrooms, tickets: task.rewardTickets }} onClick={() => window.open(task.answer, '_blank')} />
    </div>
}