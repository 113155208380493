import { useUser } from "../../../../context/UserContext";
import Start from "./Start";

export default function Body() {
    const { user } = useUser();

    if (!user) return null;

    return <div className="inner-main-part">
        <div className="big-coins-amoult ">
            <div className="coin-icon -w34"></div>
            <span>{user.mushrooms}</span>
        </div>
        <div className="duck-part">
        </div>

        <Start />
    </div>
}