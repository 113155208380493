import { useEffect, useState } from "react";
import { User } from "../types";
import UsersContoller from "../api/admin/users";
import { toast } from "react-toastify";

export const useFetchUsers = (getUsers: (allUsers: User[]) => void) => {
    const [allUsers, setAllUsers] = useState<User[]>([]);

    const getAllUsers = async () => {
        const response = await UsersContoller.getUsers();

        if (response.error) return toast(response.message);

        setAllUsers(response.users);
    }

    useEffect(() => {
        getUsers(allUsers);
    }, [allUsers, getUsers]);

    useEffect(() => {
        getAllUsers();
    }, []);

    return { allUsers, setAllUsers};
}