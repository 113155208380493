import { toast } from "react-toastify";
import TasksContoller from "../../../../api/admin/tasks";
import { Task } from "../../../../types";
import { formatDate } from "../../../../utils";

const title: { [key: string]: string } = {
    "mystery": "Еженедельный шифр викингов",
    "sub": "Подписка на канал / группу",
    "invite": "Пригласить игроков",
}

export default function Container({ task, setTasks, children }: { task: Task, setTasks: React.Dispatch<React.SetStateAction<Task[]>>, children: React.ReactNode }) {
    const complete = async () => {
        const response = await TasksContoller.completeTask(task.uuid);

        if (response.error) return toast(response.message);

        setTasks(response.tasks);

        toast("Задание завершено");
    }

    const restore = async () => {
        const response = await TasksContoller.restoreTask(task.uuid);

        if (response.error) return toast(response.message);

        setTasks(response.tasks);

        toast("Задание возобновлено");
    }

    return <div className="profit-item">
        <div className="wrap-data">
            <div className="info-with-value">
                <div className="info-data">Создано / изменено:</div>
                <div className="value-data">{formatDate(task.created_at)} / {formatDate(task.updated_at)}</div>
            </div>
            <div className="info-with-value">
                <div className="info-data">Тип задания: </div>
                <div className="value-data -yellow-info">{title[task.key]}</div>
            </div>

            {children}

            {task.active ?
                <button className="btn-s-users mt20 -red-btn-users" onClick={complete}>
                    Завершить задание
                </button> :
                <button className="btn-s-users mt20" onClick={restore}>
                    Возобновить задание
                </button>}
        </div>
    </div>
}