import { useEffect, useState } from "react";
import { Task, User } from "../../../types";
import { formatDate } from "../../../utils";
import { Link } from "react-router-dom";

export default function FarmInviteVisitGroup({ type, tasks, users }: { type: "farm" | "invite" | "visit", tasks: Task[], users: User[] }) {
    const [edited, setEdited] = useState<string | null>(null);
    const [winnersLength, setWinnersLength] = useState<number>(0);

    useEffect(() => {
        if (tasks) {
            const lastUpdatedTask = tasks.filter(task => task.key === type).sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())[0];
            setEdited(lastUpdatedTask ? formatDate(lastUpdatedTask.updated_at) : null);

            // const winners = users.filter(user => user.completedTasks.find(completedTask => completedTask.key === type));
            const winners = users.filter(user => user.completedTasks.find(completedTask => tasks.filter(task => task.active).find(task => task.uuid === completedTask.parentTaskUuid && completedTask.key === type && task.quantity === completedTask.quantity)));

            setWinnersLength(winners.length);
        }
    }, [tasks, users, type]);

    return <div className="profit-item">
        <div className="wrap-data">
            <div className="info-with-value">
                <div className="info-data">Создано / изменено:</div>
                <div className="value-data">{edited}</div>
            </div>
            <div className="info-with-value">
                <div className="info-data">Тип задания: </div>
                <div className="value-data -yellow-info">{type === "farm" ? "Фарм мухоморов в игре" : type === "invite" ? "Пригласить игроков" : "📲 Зашел в игру дней подряд"}</div>
            </div>

            {tasks.filter(task => task.key === type && task.active).map(task => <div key={task.uuid}>
                <div className="info-with-value mt20">
                    <div className="info-data">{type === "farm" ? "🕹 Зафармлено" : type === "invite" ? "👥 Количество приглашенных" : "📲 Зашел в игру подряд дней"} </div>
                    <div className="value-data -green-info">{task.quantity}</div>
                </div>
                <div className="info-with-value">
                    <div className="info-data">💰 Вознаграждение: </div>
                    <div className="value-data -green-info">{task.rewardMushrooms} мухоморов🍄</div>
                    <div className="separator">и</div>
                    <div className="value-data -green-info">{task.rewardTickets} билетов🎟 </div>
                </div>
            </div>)}

            <div className="info-with-value mt20">
                <div className="info-data">✅ Игроки получившие вознаграждение:</div>
                <div className="value-data">{winnersLength} </div>
            </div>

            <Link to={`/admp/tasks/completedfarminvitevisit/${type}`} className="btn-s-users mt10">Просмотреть список</Link>
            <Link to={`/admp/tasks/change/farminvitevisit/${type}`} className="-save-btn mt40">Изменить параметры</Link>
        </div>
    </div>
}