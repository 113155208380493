import { http } from ".";
import { ErrorResponseType, TasksResponseType, UserResponseType } from "../types";

const getTasks = async (tg_id: string): Promise<TasksResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/tasks/${tg_id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const mysteryTaskCheckAnswer = async (uuid: string, answer: string, userId: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/tasks/mystery`, { uuid, userId, answer });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const TasksController = {
    getTasks,
    mysteryTaskCheckAnswer,
};

export default TasksController;