import { http } from ".";
import { AutoFarmResponseType, ErrorResponseType } from "../types";

const startAutoFarm = async (id: string): Promise<AutoFarmResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/autofarm/start`, { id });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const statusAutoFarm = async (id: string): Promise<AutoFarmResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/autofarm/status/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

// const stopAutoFarm = async (id: string): Promise<UserResponseType | ErrorResponseType> => {
//     try {
//         const { data } = await http.post(`/autofarm/stop`, { id });
//         return data;
//     } catch (error: any) {
//         return error.response.data;
//     }
// };

const AutoFarmController = {
    startAutoFarm,
    statusAutoFarm,
    // stopAutoFarm
};

export default AutoFarmController;