import { useCallback, useEffect, useState } from "react";
import { AutoFarmType } from "../types";
import { useUser } from "../context/UserContext";
import { useError } from "../context/ErrorContext";
import AutoFarmController from "../api/autofarm";

export function useAutoFarm() {
    const { tg_id, setUser } = useUser();
    const { setError } = useError();

    const [autoFarmStarted, setAutoFarmStarted] = useState<AutoFarmType | null>(null);

    const getAutoFarmStatus = useCallback(async (tg_id: string) => {
        const response = await AutoFarmController.statusAutoFarm(tg_id);

        if (response.error) return setError(response.message);

        setAutoFarmStarted(response.status);

        if (response.user) setUser(response.user);
    }, [setError, setUser]);

    useEffect(() => {
        if (!tg_id) return;

        getAutoFarmStatus(tg_id);
    }, [tg_id, setError, setAutoFarmStarted, getAutoFarmStatus]);

    return { autoFarmStarted, setAutoFarmStarted, getAutoFarmStatus };
}