import { createContext, useContext, useEffect, useState } from "react";
import { useError } from "./ErrorContext";
import SettingsController from "../api/admin/settings";
import { GameContextType, SettingsType } from "../types";
import { useUser } from "./UserContext";

const GameContext = createContext<GameContextType>(null!);

export function GameProvider({ children }: { children: React.ReactNode }) {
    const { tg_id, setStartGame } = useUser();
    const [settings, setSettings] = useState<SettingsType | null>(null);
    const { setError } = useError();
    const [currentGameReward, setCurrentGameReward] = useState(0);
    const [time, setTime] = useState(60);
    const [checkedPortalIdx, setCheckedPortalIdx] = useState(4);

    useEffect(() => {
        const getSettings = async () => {

            const response = await SettingsController.getSettings();

            if (response.error) return setError(response.message);

            setSettings(response.settings);
        }

        getSettings();
    }, [setError]);

    useEffect(() => {
        let interval = setInterval(() => {
            setTime(time => time - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (time <= 0) {
            setStartGame(false);
        }
    }, [time, setStartGame])

    return <GameContext.Provider value={{ settings, currentGameReward, time, setTime, setCurrentGameReward, checkedPortalIdx, setCheckedPortalIdx, tg_id }}>{children}</GameContext.Provider>;
}

export function useGame() {
    return useContext(GameContext);
}
