import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TasksContoller from '../../api/admin/tasks';
import useFileUpload from '../../hooks/useFileUpload';
import Upload from '../../components/admin/tasks/inputs/Upload';
import Input from '../../components/admin/tasks/inputs/Input';
import Area from '../../components/admin/tasks/inputs/Area';

export default function CreateMystery() {
    const [rewardMushrooms, setRewardMushrooms] = useState("");
    const [rewardTickets, setRewardTickets] = useState("");
    const [text, setText] = useState('');
    const [answer, setAnswer] = useState("");
    const { file, handleFileClick, handleFileChange, fileInputRef, removeFile } = useFileUpload();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!file) {
            return toast('Пожалуйста, загрузите изображение.');
        }

        const response = await TasksContoller.createMysteryTask({
            banner: file,
            rewardMushrooms,
            rewardTickets,
            text,
            answer
        });

        if (response.error) return toast(response.message);

        return toast(response.message);
    };

    return (
        <section className="main-part-dinamic">
            <div className="wrap-of-dinamic">
                <div className="up-part-of-dinamic">
                    <Link to="/admp/tasks" className="btn-s-gradient">
                        <span>&lt; Назад</span>
                    </Link>
                </div>
                <div className="dinamic-inner-header mb40">
                    <span>⚠️ ВНИМАНИЕ! Замена загадки автоматически завершает предыдущую.</span>
                </div>
                <div className="main-header-of-page">Еженедельная загадка</div>

                <form className="dinamic-content -cmr-users -other-pages-content -verify-page" onSubmit={handleSubmit}>

                    <Upload file={file} handleFileClick={handleFileClick} handleFileChange={handleFileChange} fileInputRef={fileInputRef} removeFile={removeFile}>
                        <div className="data-for-photo">
                            <span>Баннер загадки. Максимум 4 мегабайт.</span>
                            <span>Тип файла: JPG, PNG, HEIC, JPEG</span>
                        </div>
                    </Upload>

                    <Input title="Даем мухоморов🍄" state={rewardMushrooms} setState={setRewardMushrooms} placeholder="Введите число" />

                    <Input title="Даем билетов🎟" state={rewardTickets} setState={setRewardTickets} placeholder="Введите число" />

                    <Input title="Шифр" state={answer} setState={setAnswer} placeholder="Введите ответ" />

                    <Area title="Текст для шифра" placeholder="Введите текст для шифра" state={text} setState={setText} />

                    <button className="btn-more-s -save-btn" type="submit">
                        Сохранить
                    </button>
                </form>
            </div>
        </section>
    );
}
