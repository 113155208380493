import { useCallback, useEffect, useRef, useState } from "react";
import { SettingsType } from "../types";
import UserController from "../api/user";
import { useUser } from "../context/UserContext";
import { useLevels } from "../context/LevelsContext";

type Drop = {
    type: string;
    active: {
        className: string;
        value: string;
    },
};

export const usePortal = (settings: SettingsType | null, currentGameReward: number, setCurrentGameReward: React.Dispatch<React.SetStateAction<number>>, setTime: React.Dispatch<React.SetStateAction<number>>, checkedPortalIdx: number, idx: number) => {
    const [drops, setDrops] = useState<Drop[] | null>(null);
    const [currentDrop, setCurrentDrop] = useState<Drop | null>(null);
    const [isHiddenItem, setIsHiddenItem] = useState(false);
    const [showReward, setShowReward] = useState(false);
    const [isAnimationStarted, setIsAnimationStarted] = useState(true);
    const firstRender = useRef(false);
    const { user } = useUser();
    const { levels } = useLevels();

    useEffect(() => {
        if (settings && levels && user) {
            const additionalTime = settings.additionalTime;
            const bombDecrease = settings.decreaseMushroomsWhenBomb;

            const currentLevel = levels.find(level => level.key === user.levelKey);

            if (!currentLevel) return;

            const agaricIncrease = 1 * currentLevel.multiplier

            setDrops([
                {
                    type: "timer",
                    active: {
                        className: "-add-time",
                        value: "+" + additionalTime + "s"
                    }
                },
                {
                    type: "bomb",
                    active: {
                        className: "-delete-agaric",
                        value: "-" + bombDecrease + "%"
                    }
                },
                {
                    type: "agaric",
                    active: {
                        className: "-add-agaric",
                        value: "+" + agaricIncrease
                    }
                }
            ]);
        }
    }, [settings, levels, user]);

    const resetPortal = useCallback(() => {
        if (!drops || !settings) return;

        setIsAnimationStarted(true);

        setShowReward(false);
        setIsHiddenItem(false);

        if (firstRender.current) {
            // Генерация числа от 0 до 99
            const randomChance = Math.floor(Math.random() * 100);

            // Если randomChance меньше dropChance, не выбираем итем
            if (randomChance < 100 - settings.dropChance) {
                setIsHiddenItem(true);
                return;
            }

            const chances = [
                { type: "timer", chance: settings.additionalTimeChance },
                { type: "bomb", chance: settings.bombChance },
                { type: "agaric", chance: 100 - (settings.additionalTimeChance + settings.bombChance) } // Остаток на гриб
            ];

            // Суммируем шансы
            const totalChance = chances.reduce((sum, item) => sum + item.chance, 0);

            // Случайное число от 0 до totalChance
            const random = Math.random() * totalChance;

            let cumulativeChance = 0;
            let selectedDrop = drops[0];

            // Проходим по массиву с шансами и определяем, какой предмет выбрать
            for (let i = 0; i < drops.length; i++) {
                cumulativeChance += chances[i].chance;
                if (random < cumulativeChance) {
                    selectedDrop = drops[i];
                    break;
                }
            }

            return setCurrentDrop(firstRender.current ? selectedDrop : drops[0]);
        }

        setCurrentDrop(drops[Math.floor(Math.random() * drops.length)]);

        if (!firstRender.current) {
            firstRender.current = true;
        }
    }, [drops, settings]);

    useEffect(() => {
        resetPortal();

    }, [resetPortal]);

    const handleAnimationEnd = useCallback(async () => {
        if (!settings || !levels || !user) return;

        setIsAnimationStarted(false);

        if (checkedPortalIdx === idx && !isHiddenItem) {
            setShowReward(true)

            if (currentDrop) {
                if (currentDrop.type === "timer") {
                    setTime(time => time + settings.additionalTime);
                }

                if (currentDrop.type === "bomb") {
                    const decreasedValue = Math.floor(currentGameReward * settings.decreaseMushroomsWhenBomb / 100);

                    const reward = currentGameReward - decreasedValue;
                    setCurrentGameReward(reward);
                }

                if (currentDrop.type === "agaric") {
                    const currentLevel = levels.find(level => level.key === user.levelKey);

                    if (!currentLevel) return;

                    const increasedValue = 1 * currentLevel.multiplier;

                    await UserController.increaseBalance(user.tg_id, increasedValue);

                    const reward = currentGameReward + increasedValue;
                    setCurrentGameReward(reward);
                }
            }
        }

        setTimeout(() => {
            resetPortal();
        }, Math.floor(Math.random() * 1001));

    }, [resetPortal, setTime, currentGameReward, setCurrentGameReward, checkedPortalIdx, idx, currentDrop, isHiddenItem, setIsAnimationStarted, settings, user, levels]);

    return { currentDrop, isHiddenItem, showReward, handleAnimationEnd, isAnimationStarted };
}