import { useEffect, useState } from "react";
import $ from "jquery";
import { useUser } from "../../../context/UserContext";
import { useTasks } from "../../../context/TasksContext";
import { Task } from "../../../types";
import UserController from "../../../api/user";
import duck_tg from "../../../assets/img/ducks/duck-tg.png";

export default function SubscriptionModal() {
    const { user } = useUser();
    const [showModal, setShowModal] = useState<boolean | null>(null);
    const { tasks } = useTasks()
    const [task, setTask] = useState<Task | null>(null)

    useEffect(() => {
        if (!user || !tasks) return;

        const lastSubTask = tasks.filter(task => !task.parentTaskUuid).find(t => t.uuid === user.subTaskBonusUuid);

        if (lastSubTask && user.subTaskBonusCount !== null && user.subTaskBonusCount !== undefined && user.subTaskBonusCount <= 3) {
            setTask(lastSubTask);
            setShowModal(true);

            UserController.updateSubModalInfo(user.tg_id);
        }

    }, [user, tasks]);

    if (!task) return null;

    return <>{showModal ?
        <div className="modal-window -task -subscribe-modal -open">
            <div className="close-modal -svg" onClick={() => $(".-subscribe-modal").removeClass("-open")}>
                <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg>
            </div >
            <div className="up-part-of-modal">
                <div className="left-part">
                    <div className="alert-of-modal">Оповещение!</div>
                    <div className="modal-header">
                        Подпишись на телеграм канал
                    </div>
                    <div className="will-added">
                        <span>+</span> {task.rewardMushrooms + 500}
                        <div className="coin-icon -w24"></div>
                        <span>+</span> {task.rewardTickets}
                        <div className="ticket-icon -w24"></div>
                    </div>
                </div>
                <div className="right-part">
                    <img src={duck_tg} alt="" />
                </div>

            </div>
            <div className="inner-part-of-modal">
                <div className="main-signature -modal-mb20">
                    Стань участником сообщества<br /><span>{task.desc}</span> и получи бонусные мухоморы и игровые билеты!<br />
                    <div className="-modal-mt20">{task.modalTitle}</div>
                </div>
                <div className="modal-image-wrap">
                    <img src={`/images/${task.image}`} alt="" />
                </div>
            </div>
            <div className="down-part-of-modal">
                <div className="modal-timer">
                    <div className="signature-timer">
                        Действует дополнительный бонус!
                    </div>
                </div>
                <div onClick={() => window.open(task.answer!, '_blank')} className="modal-btn -start-game">
                    <span>Подписаться</span>
                </div>
            </div>
        </div > : null}
    </>
}