import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Login from "./pages/admin/Login";
import Game from "./pages/Game";
import FaviconManager from "./components/FaviconManager";
import Users from "./pages/admin/Users";
import AdminLayout from "./components/admin/Layout";
import Tasks from "./pages/admin/Tasks";
import Messages from "./pages/admin/Messages";
import Settings from "./pages/admin/Settings";
import Invitations from "./pages/admin/Invitations";
import Banned from "./pages/admin/Banned";
import CompletedSubMystery from "./pages/admin/CompletedSubMystery";
import CreateMystery from "./pages/admin/CreateMystery";
import CreateSub from "./pages/admin/CreateSub";
import ChangeSub from "./pages/admin/ChangeSub";
import CompletedFarmInviteVisit from "./pages/admin/CompletedFarmInviteVisit";
import CreateFarmInviteVisit from "./pages/admin/CreateFarmInviteVisit";
import InactiveSubs from "./pages/admin/InactiveSubs";
import SettingsGame from "./pages/admin/SettingsGame";
import ChangeFarmInviteVisit from "./pages/admin/ChangeFarmInviteVisit";

export default function App() {
  return (
    <>
      <FaviconManager />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Game />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admp/login" element={<Login />} />

          <Route
            path="/admp"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />



          <Route
            path="/admp/invitations/:id"
            element={
              <RequireAuth>
                <Invitations />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/banned"
            element={
              <RequireAuth>
                <Banned />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks"
            element={
              <RequireAuth>
                <Tasks />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/inactivesubs/"
            element={
              <RequireAuth>
                <InactiveSubs />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/completedsubmystery/:taskUuid"
            element={
              <RequireAuth>
                <CompletedSubMystery />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/completedfarminvitevisit/:taskKey"
            element={
              <RequireAuth>
                <CompletedFarmInviteVisit />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/create/mystery"
            element={
              <RequireAuth>
                <CreateMystery />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/create/sub"
            element={
              <RequireAuth>
                <CreateSub />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/create/farminvitevisit/:taskKey"
            element={
              <RequireAuth>
                <CreateFarmInviteVisit />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/change/sub/:key"
            element={
              <RequireAuth>
                <ChangeSub />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/tasks/change/farminvitevisit/:taskKey"
            element={
              <RequireAuth>
                <ChangeFarmInviteVisit />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/messages"
            element={
              <RequireAuth>
                <Messages />
              </RequireAuth>
            }
          />
          <Route
            path="/admp/settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />

          <Route
            path="/admp/settings-game"
            element={
              <RequireAuth>
                <SettingsGame />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
