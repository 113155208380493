import empty_chan from "../../../../assets/img/game/chan/empty-chan.png";
import blur_chan from "../../../../assets/img/game/chan/blur-chan.png";
import platform from "../../../../assets/img/game/platform.png";
import { usePortal } from "../../../../hooks/usePortal";
import { useGame } from "../../../../context/GameContext";
import { useEffect, useState } from "react";

export default function Portal({ idx }: { idx: number }) {
    const { settings, currentGameReward, setCurrentGameReward, setTime, checkedPortalIdx, setCheckedPortalIdx } = useGame();
    const { currentDrop, isHiddenItem, showReward, handleAnimationEnd, isAnimationStarted } = usePortal(settings, currentGameReward, setCurrentGameReward, setTime, checkedPortalIdx, idx);

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const images = [empty_chan, blur_chan, platform];
        const promises = images.map((src) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
            });
        });

        Promise.all(promises).then(() => {
            setImagesLoaded(true);
        });
    }, []);

    if (!imagesLoaded || !currentDrop) return null;

    return <div className={`portal-with-platform -pp${idx}-part`}>
        <div className="portal-wrap">
            <div className={`light-of-portal -portal${idx}`}>
            </div>
        </div>
        {isAnimationStarted && <div className="agaric-line">
            <div className={`game-item -${currentDrop.type}`} style={{ animation: `game-item${idx} ${idx === 1 ? 1.28 : idx === 2 ? 1.26 : 1.30}s ease`, visibility: isHiddenItem ? "hidden" : "visible" }} onAnimationEnd={handleAnimationEnd}></div>
        </div>}
        <div className="platform-wrap">
            <div className={`game-chan -pp${idx} ${checkedPortalIdx === idx ? "active" : ""}`}>
                <div className="main-chan">
                    <img src={empty_chan} alt="" />
                </div>
                <div className="blur-chan">
                    <img src={blur_chan} alt="" />
                </div>
            </div>

            <div className={`touch-circle-wrap -pp${idx}-platform`}>
                <label className="touch-circle">
                    <input type="radio" checked={checkedPortalIdx === idx} name="portal" value={idx} onChange={() => setCheckedPortalIdx(idx)} style={{ display: "none" }} />
                </label>
            </div>

            {showReward && <div className="added-wrap -active">
                <div className={`added-items ${currentDrop.active.className}`}>
                    {currentDrop.active.value}
                </div>
            </div>}

            <img src={platform} alt="platform1" />
        </div>
    </div>
}