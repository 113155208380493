import { Task } from "../../../../types";
import Status from "./layout/Status";
import $ from "jquery";

interface Props {
    task: Task;
}

export default function Invite({ task }: Props) {
    return <div className={`block-info -fdc -referal ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <g id="_05" data-name="05">
                        <circle className="cls-1" cx="5" cy="3" r="3"></circle>
                        <path className="cls-1" d="M7,6H3c-1.65,0-3,1.35-3,3,0,.55.45,1,1,1h8c.55,0,1-.45,1-1,0-1.65-1.35-3-3-3Z"></path>
                        <circle className="cls-1" cx="23" cy="3" r="3"></circle>
                        <path className="cls-1" d="M25,6h-4c-1.65,0-3,1.35-3,3,0,.55.45,1,1,1h8c.55,0,1-.45,1-1,0-1.65-1.35-3-3-3Z"></path>
                        <circle className="cls-1" cx="14" cy="7" r="3"></circle>
                        <path className="cls-1" d="M10.86,13.04l.97.96h4.34l.97-.96c.48-.48,1.07-.79,1.71-.94-.39-1.21-1.51-2.1-2.85-2.1h-4c-1.34,0-2.46.89-2.85,2.1.64.15,1.23.46,1.71.94Z"></path>
                        <path className="cls-1" d="M23,13h-3.36c-.68,0-1.31.26-1.79.74l-1.1,1.1c-.1.1-.24.16-.38.16h-4.73c-.14,0-.28-.06-.38-.16l-1.1-1.1c-.48-.48-1.12-.74-1.79-.74h-3.36c-2.95,0-5,4.22-5,8s2.2,7,5,7c2.05,0,3.82-2.02,4.32-4,.24-.96,1.1-2,2.09-2h5.19c.99,0,1.85,1.04,2.09,2,.5,1.98,2.27,4,4.32,4,2.8,0,5-3.07,5-7s-2.05-8-5-8ZM7.5,19h-1.5v1.5c0,.28-.22.5-.5.5s-.5-.22-.5-.5v-1.5h-1.5c-.28,0-.5-.22-.5-.5s.22-.5.5-.5h1.5v-1.5c0-.28.22-.5.5-.5s.5.22.5.5v1.5h1.5c.28,0,.5.22.5.5s-.22.5-.5.5ZM22,15c.55,0,1,.45,1,1s-.45,1-1,1-1-.45-1-1,.45-1,1-1ZM20,19c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1ZM22,21c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1ZM24,19c-.55,0-1-.45-1-1s.45-1,1-1,1,.45,1,1-.45,1-1,1Z"></path>
                    </g>
                </svg>
            </div>
            <div className="main-txt -fz14">
                Пригласи {task.quantity} друзей <br /> по своей ссылке
            </div>
        </div>

        <Status reward={{ mushrooms: task.rewardMushrooms, tickets: task.rewardTickets }} onClick={() => $('.modal-window.-add-a-friends.invite-modal').addClass('-open')} />
    </div>
}