import { useEffect, useRef } from "react";
import { useAutoFarm } from "../../../../hooks/useAutoFarm";
import { useError } from "../../../../context/ErrorContext";
import { useUser } from "../../../../context/UserContext";
import AutoFarmController from "../../../../api/autofarm";
import { formatTime } from "../../../../utils";

export default function AutoFarm() {
    const { tg_id, setUser, user } = useUser();
    const { setError } = useError();
    const { autoFarmStarted, setAutoFarmStarted, getAutoFarmStatus } = useAutoFarm();
    const hasFetchedInitially = useRef(false);

    useEffect(() => {
        if (!tg_id) return;

        let interval: NodeJS.Timeout;

        if (autoFarmStarted) {
            if (!hasFetchedInitially.current) {
                getAutoFarmStatus(tg_id);
                hasFetchedInitially.current = true;
            }

            interval = setInterval(async () => {
                await getAutoFarmStatus(tg_id);
            }, 5000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [tg_id, autoFarmStarted, setAutoFarmStarted, setError, setUser, getAutoFarmStatus]);

    const startAutoFarm = async () => {
        if (!tg_id) return setError("Вы не авторизованы");

        const response = await AutoFarmController.startAutoFarm(tg_id);

        if (response.error) return setError(response.message);

        setAutoFarmStarted(response.status);
    };

    if (!user) return null;

    return <div className="block-info -fdc -show-autofarm">
        <div className="left-part">
            <div className="main-txt -fz14">
                Автофарм (8 час)
            </div>
        </div>
        {!autoFarmStarted ?
            <button className="btn-action -start-autofarm" onClick={startAutoFarm}>
                <div className="signature-btn-action">
                    Зафармить
                </div>
                <div className="will-added value-btn-action">
                    +
                    <div className="coin-icon -w14"></div>
                </div>
            </button> :
            <div className="autofarm active">
                {autoFarmStarted.endTime > 0 && <div className="timer-for-autofarm">
                    {formatTime(autoFarmStarted.endTime)}
                </div>}
                {autoFarmStarted.lastReward > 0 && <div className="will-added value-btn-action">
                    + {autoFarmStarted.lastReward.toFixed(3)}
                    <div className="coin-icon -w14"></div>
                </div>
                }
            </div>}
    </div>;
}