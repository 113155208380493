import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { Task } from "../../types";
import { toast } from "react-toastify";
import TasksContoller from "../../api/admin/tasks";
import FarmInviteVisitForm from "../../components/admin/tasks/FarmInviteVisitForm";

export default function ChangeFarmInviteVisit() {
    const { taskKey } = useParams();
    const [tasks, setTasks] = useState<Task[]>([]);

    useEffect(() => {
        const getTask = async () => {
            if (!taskKey) return;

            const response = await TasksContoller.getTasks();

            if (response.error) return toast(response.message);

            setTasks(response.tasks.filter(task => task.key === taskKey));
        }

        getTask();
    }, [taskKey]);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="up-part-of-dinamic">
                <Link to={`/admp/tasks`} className="btn-s-gradient">
                    <span>&lt; Назад</span>
                </Link>
            </div>
            <div className="dinamic-inner-header mb40">
                <span>⚠️ ВНИМАНИЕ! Корректировки не вносятся в предыдущие достижения игрков.
                </span>
            </div>
            <div className="main-header-of-page">
                Задания: {taskKey === "farm" ? "фарм мухоморов🍄 в игре" : taskKey === "invite" ? "пригласить игроков" : "📲 зашел в игру дней подряд"}
            </div>

            <div className="dinamic-content -cmr-users -other-pages-content ">
                {tasks.filter(task => task.active).map((task, index) => (
                    <FarmInviteVisitForm task={task} index={index} key={task.uuid} setTasks={setTasks} />
                ))}

                <Link to={`/admp/tasks/create/farminvitevisit/${taskKey}`} className="btn-s-gradient mt40" style={{ display: "inline-block" }}>
                    <span>+ Добавить отметку</span>
                </Link>


            </div>


        </div>
    </section>
}