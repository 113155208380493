export default function Status({ reward, onClick, title }: { reward: { mushrooms: number, tickets: number }, onClick: () => void, title?: string }) {
    return <>
        <div className="done-action">
            <div className="signature-btn-action">
                Получил
            </div>
            <div className="will-added value-btn-action">
                + {reward.mushrooms}
                <div className="coin-icon -w14"></div>
            </div>
            <div className="will-added value-btn-action">
                + {reward.tickets}
                <div className="ticket-icon -w14"></div>
            </div>
        </div>
        <div className="btn-action -open-guess-a-riddle" onClick={onClick}>
            <div className="signature-btn-action">
                {title || 'Выполнить'}
            </div>
            <div className="will-added value-btn-action">
                + {reward.mushrooms}
                <div className="coin-icon -w14"></div>
            </div>
            <div className="will-added value-btn-action">
                + {reward.tickets}
                <div className="ticket-icon -w14"></div>
            </div>
        </div>
    </>
}