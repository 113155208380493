import { useEffect } from "react";
import { useTasks } from "../../../context/TasksContext";
import { useUser } from "../../../context/UserContext";
import AddFriends from "../modal/AddFriends";
import MysteryTask from "../modal/MysteryTask";
import Body from "./Body";
import Header from "./Header";

export default function Tasks() {
    const { tasks } = useTasks();
    const { user, getUser } = useUser();

    useEffect(() => {
        getUser();
    }, [getUser]);

    if (!tasks || !user) return null;

    return <>
        <Header tasks={tasks} user={user} />

        <Body tasks={tasks} user={user} />

        <MysteryTask />

        <AddFriends />
    </>
}