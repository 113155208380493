import { useGame } from "../../../../context/GameContext";
import { useUser } from "../../../../context/UserContext";
import { formatGameTimer } from "../../../../utils";

export default function Header() {
    const { setStartGame } = useUser();
    const { time, currentGameReward } = useGame();

    return <div className="up-part-profile">
        <div className="left-part -timer-wrap">
            <div className="timer-icon">
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 511.73">
                    <path className="cls-1" d="M368,151.47h2.13l29.87-29.87-29.87-29.87-32,32c-30.39-20.81-65.69-33.31-102.4-36.27v-44.8h42.67V0h-128v42.67h42.67v44.8c-36.71,2.95-72.01,15.46-102.4,36.27l-32-32-29.87,29.87,29.87,29.87c-81.15,85.42-77.69,220.45,7.73,301.6,85.42,81.15,220.45,77.69,301.6-7.73,78.22-82.34,78.22-211.53,0-293.87ZM214.4,469.33c-94.26,0-170.67-76.41-170.67-170.67s76.41-170.67,170.67-170.67,170.67,76.41,170.67,170.67-76.41,170.67-170.67,170.67Z"></path>
                    <path className="cls-1" d="M214.4,149.33c-82.48,0-149.33,66.86-149.33,149.33s66.86,149.33,149.33,149.33,149.33-66.86,149.33-149.33-66.86-149.33-149.33-149.33ZM233.6,315.73c-10.02,10.02-26.25,10.02-36.27,0s-10.01-26.25,0-36.27l102.4-66.13-66.13,102.4Z"></path>
                </svg>
            </div>
            <div className="name-with-level">
                <div className="time">{formatGameTimer(time)}</div>
                <div className="progress-line">
                    <div className="actual-progress" style={{ width: "0%" }}></div>
                </div>
            </div>
            <button className="btn-action" onClick={() => setStartGame(false)}>
                <div className="signature-btn-action">
                    Прервать
                </div>
                <div className="will-added value-btn-action">
                    -
                    <div className="ticket-icon -w14"></div>
                </div>
            </button>
        </div>
        <div className="right-part">
            <div className="big-coins-amoult">
                <span>+ {currentGameReward}</span>
                <div className="coin-icon -w34"></div>
            </div>
        </div>
    </div>
}