import { TasksType, User } from "../../../types";

interface Props {
    tasks: TasksType;
    user: User;
}

export default function Header({ tasks, user }: Props) {
    if (!tasks || !user) return null;

    return <div className="up-of-inner-main-part">
        <div className="amoult-wrap">
            <div className="amoult-info -fz18">
                <div className="signature-for-amoult-info">
                    Заданий
                </div>
                <div className="value-for-amoult-info">
                    {tasks.length}
                </div>
            </div>
            <div className="amoult-info -fz14">
                <div className="signature-for-amoult-info">
                    Выполнено
                </div>
                <div className="value-for-amoult-info">
                    {tasks.filter(task => task.parentTaskUuid).length || 0}
                </div>
            </div>
        </div>
    </div>
}