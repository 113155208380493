import throne_duck from "../../../assets/img/ducks/throne-duck.png";
import level_up from "../../../assets/img/icons/level-up.png";
import ducks_tg from "../../../assets/img/ducks/duck-tg.png";

export default function Secret() {
    return <>
        <div className="up-of-inner-main-part">
            <div className="main-header">
                Секретная база
            </div>
        </div>
        <div className="inner-main-part -aifs">
            <div className="contest-wrap -secret-base">
                <div className="block-info -aic">
                    <div className="discription-for-contest">
                        Участвуйте в конкурсах и получайте денежные вознаграждения!
                    </div>
                    <div className="left-part">
                        <div className="decor-duck"><img src={throne_duck} alt="" /></div>
                    </div>
                    <div className="right-part">
                        <div className="contest-prize">
                            <span>+</span>
                            <div className="ton-icon -w14"></div>
                            <span>+</span>
                            <div className="duck-coin -w14"></div>
                        </div>
                        <div className="btn-action -dont-touch">
                            <div className="signature-btn-action">
                                ⚙️ В разработке
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-info -aic -skins">
                    <div className="discription-for-contest">
                        Магазин скинов
                    </div>
                    <div className="left-part">
                        <div className="decor-duck"><img src={level_up} alt="" /></div>
                    </div>
                    <div className="right-part">
                        <div className="btn-action -dont-touch">
                            <div className="signature-btn-action">
                                ⚙️ В разработке
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-info -aic -telegram">
                    <div className="discription-for-contest">
                        <span>Подпишись</span> на наш канал и будь в курсе всех событий!
                    </div>
                    <div className="left-part">
                        <div className="decor-duck"><img src={ducks_tg} alt="" /></div>
                    </div>
                    <div className="right-part">
                        <button onClick={() => window.open(process.env.REACT_APP_CHANNEL_LINK!, '_blank')} className="btn-action">
                            <div className="signature-btn-action">
                                Перейти
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}