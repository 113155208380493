import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { TasksContextType, TasksType } from "../types";
import TasksController from "../api/tasks";
import { useError } from "./ErrorContext";
import { useUser } from "./UserContext";

export const TasksContext = createContext<TasksContextType | undefined>(undefined);

export function TasksProvider({ children }: { children: React.ReactNode }) {
    const { user } = useUser();
    const [tasks, setTasks] = useState<TasksType | null>(null);
    const { setError } = useError();

    const getTasks = useCallback(async () => {
        if (!user) return;
        
        const response = await TasksController.getTasks(user.tg_id);

        if (response.error) return setError(response.message);

        setTasks([...response.uncompletedTasks, ...response.completedTasks]);

    }, [setError, user]);

    useEffect(() => {
        getTasks();
    }, [getTasks]);

    return <TasksContext.Provider value={{ tasks }}>{children}</TasksContext.Provider>;
}

export const useTasks = () => {
    const context = useContext(TasksContext);

    if (!context) {
        throw new Error("useTabs must be used within a GameProvider");
    }
    return context;
};