import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FaviconManager = () => {
    const location = useLocation();

    useEffect(() => {
        const url = location.pathname.startsWith('/admp') ? '/assets/crm-favicon.png' : '/assets/user-favicon.png';

        changeFavicon(url);
    }, [location.pathname]);

    const changeFavicon = (url: string) => {
        let link = document.querySelector("link[rel='icon']") as HTMLLinkElement;

        if (!link) {
            link = document.createElement("link") as HTMLLinkElement;
            link.rel = 'icon';
            document.head.appendChild(link);
        }

        link.href = url;
    };

    return null;
};

export default FaviconManager;
