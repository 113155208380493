import { ErrorResponseType, GetSettingsResponseType, UpdateSettingsResponseType } from "../../types";
import http from "./index";

const updateSettings = async (mushrooms: string, tickets: string): Promise<UpdateSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/settings`, {
            mushrooms,
            tickets
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const updateGameSettings = async (key: string, value: string): Promise<UpdateSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/settings-game`, {
            value,
            key
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const getSettings = async (): Promise<GetSettingsResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/settings`);

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const SettingsController = {
    updateSettings,
    getSettings,
    updateGameSettings
}

export default SettingsController;