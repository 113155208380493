import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAdmin } from "../../context/AdminContext";
import { useState } from "react";

export default function Login() {
    const auth = useAdmin();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState<string>("");

    const from = location.state?.from?.pathname || "/admp";

    if (auth.admin) return <Navigate to={from} replace />;

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setError("");

        const formData = new FormData(event.currentTarget);

        const response = await auth.signIn(formData.get("login") as string, formData.get("password") as string);

        if (response.error) {
            return setError(response.message);
        }

        navigate(from, { replace: true });
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input name="login" type="text" />

                <br />

                <input name="password" type="password" />

                <br />

                <div>{error}</div>

                <button type="submit" style={{all: "unset"}}>Login</button>
            </form>
        </div>
    );
}
