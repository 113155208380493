import { useEffect, useRef } from "react";
import agaric from "../../../../assets/img/agaric.png";
import cauldron from "../../../../assets/img/cauldron.png";
import { useUser } from "../../../../context/UserContext";
import useAutoReward from "../../../../hooks/useAutoReward";
import { formatTime } from "../../../../utils";
import $ from "jquery";

export default function Start() {
    const { tg_id, user, setStartGame } = useUser();
    const { autoRewardStarted, getAutoRewardStatus } = useAutoReward();
    const hasFetchedInitially = useRef(false);

    useEffect(() => {
        if (!tg_id) return;

        let interval: NodeJS.Timeout;

        if (autoRewardStarted) {
            if (!hasFetchedInitially.current) {
                getAutoRewardStatus(tg_id);
                hasFetchedInitially.current = true;
            }

            interval = setInterval(async () => {
                await getAutoRewardStatus(tg_id);
            }, 5000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [tg_id, autoRewardStarted, getAutoRewardStatus]);

    if (!user) return null;

    return <div className="start-game-block">
        <div className="left-part-decor">
            <div className="decor-agaric -d1a">
                <img src={agaric} alt="" />
            </div>
            <div className="decor-agaric -d2a">
                <img src={agaric} alt="" />
            </div>
            <div className="decor-chan">
                <img src={cauldron} alt="" />
            </div>
        </div>
        <div className="right-part-start-game">
            <div className="up-part-of-start-game">
                {user.tickets > 0 ?
                    <>
                        <div className="tickets">
                            <span>Билеты:</span>
                            <div className="will-added value-btn-action">
                                {user.tickets}
                                <div className="ticket-icon -w14"></div>
                            </div>
                        </div>
                        <button className="btn-action" onClick={() => { setStartGame(true) }}>
                            <div className="signature-btn-action">
                                Играть
                            </div>
                        </button>
                    </>
                    :
                    <>
                        {autoRewardStarted && <div className="tickets -waiting-new-day">
                            <span> {formatTime(autoRewardStarted.endTime)}</span>
                            <div className="will-added value-btn-action">
                                + 5
                                <div className="ticket-icon -w14"></div>
                            </div>
                        </div>}

                        <button className="btn-action -open-add-a-friends" onClick={() => { $('.modal-window.-add-a-friends').addClass('-open') }}>
                            <div className="signature-btn-action">
                                Получить
                            </div>
                            <div className="will-added value-btn-action">
                                <div className="ticket-icon -w14"></div>
                            </div>
                        </button>
                    </>
                }
            </div>
            <div className="down-part-of-start-game">
                Собери мухоморы
            </div>
            <div className="signature-dropgame">
                DropGame
            </div>
        </div>
    </div>
}