import { LevelsType, User } from "../../../types";
import { formatNumber, sliceUsername } from "../../../utils";
import $ from "jquery";
import arrow from "../../../assets/img/icons/arrow.svg";
import info from "../../../assets/img/icons/info.svg";
import { useLevels } from "../../../context/LevelsContext";
import { useTabs } from "../../../context/TabsContext";

interface Props {
    user: User;
}

export default function ContainerHeader({ user }: Props) {
    const { levels } = useLevels();
    const { setActiveTab } = useTabs();

    if (!levels) return null;

    return <div className="up-part-profile">
        <div className="left-part">
            <div className="avatar-dealer">
                <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470.88 511">
                    <path className="cls-1" d="M67.16,78.06L0,143.7l12.8,7.93c7.42,4.59,15.84,6.92,24.3,6.92,6.42,0,12.87-1.34,18.92-4.06l55.76-31.17.81-.85-45.43-44.42Z"></path>
                    <path className="cls-1" d="M269.71,82.42c-.73-22.37-10.03-43.22-26.18-58.72C227.35,8.19,206.1-.23,183.68,0c-22.42.23-43.49,9.1-59.34,24.95l-35.43,35.43,44.74,43.74c9.54,9.33,10.3,24.52,1.79,34.75l-46.32,48.12c-24.55,25.5-37.89,59.03-37.55,94.43s14.3,68.67,39.34,93.7l2.37,2.37c12.78,12.78,27.72,22.59,43.99,29.11-18.86-23.29-30.17-52.92-30.17-85.15,0-72,56.44-131.06,127.4-135.27-4.8-2.87-10.07-5.74-15.84-8.38l29.05-35.41c14.93-16.46,22.75-37.75,22.02-59.98ZM190.48,72.75h-29.82v-29.82h29.82v29.82Z"></path>
                    <path className="cls-1" d="M470.88,239.27v-24.5h-228.28c-58.83,0-106.69,47.86-106.69,106.7s47.86,106.69,106.69,106.69h28.85l-12.44,52.89h-27.66l5.42-24.2c-10.23-.43-20.18-2.01-29.71-4.6l-6.55,28.8h-56.63v29.95h138.63l20.05-83.96c94.51-10.29,168.32-90.57,168.32-187.77Z"></path>
                </svg>
            </div>
            <div className="name-with-level">
                <div className="tg-name">{sliceUsername(user.tg_username || user.tg_firstname)}</div>
                <div className="data-level-wrap -open-level-decks">
                    <div className="data-of-level" onClick={() => { $(`.modal-window.-level-decks`).addClass("-open") }}>
                        <div className="text-btn -second-style">
                            {levels.find((level) => level.key === user.levelKey)?.level_name} <span><img src={arrow} alt="" /></span>
                        </div>
                        {user.levelKey !== "konun" && <div className="actual-level">
                            {user.level.user_level}<span>/{levels.length}</span>
                        </div>}
                    </div>
                    <div className="progress-line">
                        <div className="actual-progress" style={{ width: `${user.levelKey !== "konun" ? getRange(levels, user.level.user_level, user.mushrooms).percentageProgress : 100}% ` }}></div>
                    </div>
                </div>


            </div>
        </div>
        <div className="right-part">
            <div className="amoult-of-coins">
                <div className="coins">
                    <span>{formatNumber(user.mushrooms)}</span>
                    <div className="coin-wrap -w24"></div>
                </div>
                <button className="boost" onClick={() => { setActiveTab("secret"); }}><span>+</span></button>
            </div>
            <div className="text-btn -second-style -open-about-project" onClick={() => { $(".modal-window.-about-project").addClass("-open") }}>Про игру <span><img src={info} alt="" className="w6px" /></span></div>
        </div>
    </div>
}

function getRange(levels: LevelsType, user_level: number, balance: number) {
    // Найти максимальный и минимальный баланс для данного уровня пользователя
    const currentLevel = levels.find(level => level.user_level === user_level);

    const maxBalance = currentLevel!.end_balance;
    const minBalance = currentLevel!.start_balance;

    // Определить диапазон одного уровня
    const totalRange = maxBalance - minBalance;
    const levelRange = totalRange / 10;

    // Рассчитать уровень
    let level = Math.floor((balance - minBalance) / levelRange) + 1;

    // Убедиться, что уровень не превышает 7
    if (level > 10) {
        level = 10;
    }

    // Рассчитать процентное соотношение текущего прогресса в уровне
    const progressWithinLevel = ((balance - minBalance) % levelRange) / levelRange;
    const percentageProgress = (level - 1) * 10 + progressWithinLevel * 10;

    return {
        level, percentageProgress
    }
}