import { ChangeEvent, useEffect, useState } from "react";
import { User as UserType } from "../../../types";
import { filterByInput } from "./Filters";

export default function FilterByName({ users, allUsers, filteredUsers, setFilteredUsers }: { users: UserType[], allUsers: UserType[], filteredUsers: UserType[], setFilteredUsers: React.Dispatch<React.SetStateAction<UserType[]>> }) {
    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch("");
    }, [allUsers, setSearch]);

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value.toLowerCase();

        setSearch(search);

        const filteredByInput = filterByInput(users, search);

        setFilteredUsers(filteredByInput);
    }

    return <>
        <div className="search-part">
            <div className="input-wrap">
                <input type="text" placeholder="ID telegram / @username / Имя" onChange={onChangeInput} value={search} />
                <div className="error-input">
                    Ничего не найдено
                </div>
            </div>
            <button className="search-btn">
                <div className="icon-div search"></div>
            </button>
        </div>
        <div className="dinamic-inner-header" style={{ fontSize: "14px" }}>
            <span>Найдено по фильтру</span><span>{filteredUsers.length}</span>
        </div>
    </>
}