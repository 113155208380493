import http from "./index";
import { ErrorResponseType, UsersResponseType } from "../../types";

const getUsers = async (): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.get(`/admin/users/`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const banUser = async (id: string): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/ban/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const unbanUser = async (id: string): Promise<UsersResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/unban/${id}`);
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const UsersContoller = {
    getUsers,
    banUser,
    unbanUser,
}

export default UsersContoller;