import empty_chan from "../../../../assets/img/game/chan/empty-chan.png";
import blur_chan from "../../../../assets/img/game/chan/blur-chan.png";
import platform from "../../../../assets/img/game/platform.png";
import { useGame } from "../../../../context/GameContext";
import { useEffect, useState } from "react";

export default function PortalBack() {
    const { checkedPortalIdx, setCheckedPortalIdx } = useGame();
    const idx = 4;

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const images = [empty_chan, blur_chan, platform];
        const promises = images.map((src) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
            });
        });

        Promise.all(promises).then(() => {
            setImagesLoaded(true);
        });
    }, []);

    if (!imagesLoaded) return null;

    return <>
        <div className="game-chan -default-position -back-pp" style={{ display: checkedPortalIdx === idx ? "block" : "none" }}>
            <div className="main-chan">
                <img src={empty_chan} alt="" />
            </div>
            <div className="blur-chan">
                <img src={blur_chan} alt="" />
            </div>
        </div>
        <div className="down-game-decor-wrap">
            <div className="touch-circle-wrap -back-platform">
                <label className="touch-circle">
                    <input type="radio" checked={checkedPortalIdx === idx} name="portal" value={idx} onChange={() => setCheckedPortalIdx(idx)} style={{ display: "none" }} />
                </label>
            </div>
            <div className="down-game-decor">
                <img src={platform} alt="" />
            </div>
        </div>
    </>
}