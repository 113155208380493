import Input from "../tasks/inputs/Input";

interface SettingFormProps {
    title: string;
    state: string;
    setState: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (key: string, value: string) => void;
    settingKey: string;
}

const SettingForm: React.FC<SettingFormProps> = ({ title, state, setState, onSubmit, settingKey }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await onSubmit(settingKey, state);
    };

    return (
        <form className="dinamic-content -cmr-users -other-pages-content" onSubmit={handleSubmit}>
            <div className="-profile-setting mt40">
                <div className="right-part-of-secondary-pages">
                    <div className="dark-block-content -full-bg">
                        <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                            {title}
                        </div>

                        <Input title="" state={state} setState={setState} placeholder="" />

                        <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Обновить</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SettingForm;
