import { toast } from "react-toastify";
import useFileUpload from "../../../hooks/useFileUpload";
import Upload from "../tasks/inputs/Upload";
import Input from "../tasks/inputs/Input";
import { useState } from "react";
import Area from "../tasks/inputs/Area";
import MessagesContoller from "../../../api/admin/messages";

export default function Message({ type }: { type: "mass" | "zakrep" }) {
    const { file, handleFileClick, handleFileChange, fileInputRef, removeFile } = useFileUpload();
    const [link, setLink] = useState("");
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!file) {
            return toast('Пожалуйста, загрузите изображение.');
        }

        const response = await MessagesContoller.createMessage({
            type,
            image: file,
            link,
            message,
            title
        });

        if (response.error) return toast(response.message);

        return toast(response.message);
    };

    return <>
        <div className={`main-header-of-page ${type === "zakrep" ? "mt60" : ""}`}>
            {type === "zakrep" ? "Закрепленное сообщение" : "Массовое сообщение"}
        </div>

        <form className="dinamic-content -cmr-users -other-pages-content -verify-page" onSubmit={handleSubmit}>
            <Upload file={file} handleFileClick={handleFileClick} handleFileChange={handleFileChange} fileInputRef={fileInputRef} removeFile={removeFile}>
                <div className="data-for-photo">
                    <span>Максимум 10 мегабайт</span>
                    <span>Тип файла: JPG, PNG, HEIC, JPEG</span>
                </div>
            </Upload>

            <Input title="Добавить ссылку в пост (если требуется)" state={link} setState={setLink} placeholder="Ссылка" />

            <Input title="Название кнопки со ссылкой (если указана ссылка)" state={title} setState={setTitle} placeholder="Ссылка" />

            <Area title={`Текст для ${type === "zakrep" ? "закрепленного сообщения" : "массового сообщения"}`} placeholder="Введите массовое сообщение" state={message} setState={setMessage} />

            <button className="btn-more-s -save-btn" type="submit">
                Сохранить
            </button>
        </form>
    </>
}