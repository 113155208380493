import { GameProvider } from "../../../../context/GameContext";
import Body from "./Body";
import Header from "./Header";

export default function GameContainer() {
    return <GameProvider>
        <div className="inner-app-part game-wrap">
            <Header />

            <Body />
        </div>
    </GameProvider>
}