import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { User } from "../../../types";

export function filterByInput(users: User[], search: string) {
    return users.filter((user) => {
        return user.tg_id?.toString()?.toLowerCase().includes(search) || user.tg_username?.toString()?.toLowerCase().includes(search) || user.tg_firstname?.toString()?.toLowerCase().includes(search) || user.tg_lastname?.toString()?.toLowerCase().includes(search);
    });
}

export default function Checkboxes({ users, allUsers, filteredUsers, setFilteredUsers }: { users: User[], allUsers: User[], filteredUsers: User[], setFilteredUsers: (users: User[]) => void }) {
    const [search, setSearch] = useState("");

    const [filterLengths, setFilterLengths] = useState({
        used_tasks: 0,
        used_friends: 0,
        inactive: 0,
    });

    const [checkboxStates, setCheckboxStates] = useState({
        used_tasks: false,
        used_friends: false,
        inactive: false,
        farmer: false,
        warrior: false,
        drang: false,
        hold: false,
        foring: false,
        yarl: false,
        konun: false,
        top100: false
    });

    const resetCheckboxes = useCallback(() => {
        setCheckboxStates(prevCheckboxStates => {
            const newCheckboxStates = { ...prevCheckboxStates };

            Object.keys(newCheckboxStates).forEach(k => {
                newCheckboxStates[k as keyof typeof checkboxStates] = false;
            });
            return newCheckboxStates;
        });
    }, []);

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>, key: "used_tasks" | "used_friends" | "inactive" | "farmer" | "warrior" | "drang" | "hold" | "foring" | "yarl" | "konun" | "top100") => {
        resetCheckboxes();

        setCheckboxStates(checkboxStates => ({ ...checkboxStates, [key]: event.target.checked }));

        const filteredByInput = filterByInput(users, search);

        if (!event.target.checked) return setFilteredUsers(filteredByInput);

        if (key === "used_tasks") {
            const filteredByKey = filteredByInput.filter((user) => user.completedTasks.length > 0);
            setFilteredUsers(filteredByKey);
        }

        if (key === "used_friends") {
            const filteredByKey = filteredByInput.filter((user) => user.friends.length > 0);
            setFilteredUsers(filteredByKey);
        }

        if (key === "inactive") {
            const filteredByKey = filteredByInput.filter((user) => (!user.lastVisit || user.lastVisit < new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)) || user.mushrooms < 8000);
            setFilteredUsers(filteredByKey);
        }

        if (key === "farmer") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "farmer");
            setFilteredUsers(filteredByKey);
        }

        if (key === "warrior") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "warrior");
            setFilteredUsers(filteredByKey);
        }

        if (key === "drang") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "drang");
            setFilteredUsers(filteredByKey);
        }

        if (key === "hold") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "hold");
            setFilteredUsers(filteredByKey);
        }

        if (key === "foring") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "foring");
            setFilteredUsers(filteredByKey);
        }

        if (key === "yarl") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "yarl");
            setFilteredUsers(filteredByKey);
        }

        if (key === "konun") {
            const filteredByKey = filteredByInput.filter((user) => user.levelKey === "konun");
            setFilteredUsers(filteredByKey);
        }

        if (key === "top100") {
            const filteredByKey = [...filteredByInput].sort((a, b) => b.mushrooms - a.mushrooms).slice(0, 100);
            setFilteredUsers(filteredByKey);
        }
    }

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value.toLowerCase();

        setSearch(search);

        const filteredByInput = filterByInput(users, search);

        setFilteredUsers(filteredByInput);

        resetCheckboxes();
    }

    useEffect(() => {
        resetCheckboxes();
        setSearch("");
    }, [allUsers, resetCheckboxes]);

    useEffect(() => {
        function rewriteFilterLengths() {
            const filteredByInput = filterByInput(users, search);
            const used_tasks = filteredByInput.filter((user) => user.completedTasks.length > 0).length;
            const used_friends = filteredByInput.filter((user) => user.friends.length > 0).length;
            const inactive = filteredByInput.filter((user) => (!user.lastVisit || user.lastVisit < new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)) || user.mushrooms < 8000).length;

            setFilterLengths({
                used_tasks,
                used_friends,
                inactive,
            });
        }

        rewriteFilterLengths();
    }, [search, users]);

    return <>
        <div className="search-part">
            <div className="input-wrap">
                <input type="text" placeholder="ID telegram / @username / Имя" onChange={onChangeInput} value={search} />
            </div>
            <button className="search-btn">
                <div className="icon-div search"></div>
            </button>
        </div>
        <div className="filter-checkbox">
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders1" onChange={(event) => onChangeCheckbox(event, "used_tasks")} checked={checkboxStates.used_tasks} />
                <label htmlFor="addtomoders1">Выполнили задания <span className="-yellow-info">{filterLengths.used_tasks}</span> </label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders2" onChange={(event) => onChangeCheckbox(event, "used_friends")} checked={checkboxStates.used_friends} />
                <label htmlFor="addtomoders2">Привели игроков <span className="-green-info">{filterLengths.used_friends}</span> </label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders3" onChange={(event) => onChangeCheckbox(event, "inactive")} checked={checkboxStates.inactive} />
                <label htmlFor="addtomoders3">Неактивные<span className="-red-info">{filterLengths.inactive}</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders4" onChange={(event) => onChangeCheckbox(event, "farmer")} checked={checkboxStates.farmer} />
                <label htmlFor="addtomoders4">Уровень <span>Фермер</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders5" onChange={(event) => onChangeCheckbox(event, "warrior")} checked={checkboxStates.warrior} />
                <label htmlFor="addtomoders5">Уровень <span>Воин</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders6" onChange={(event) => onChangeCheckbox(event, "drang")} checked={checkboxStates.drang} />
                <label htmlFor="addtomoders6">Уровень <span>Дренг</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders7" onChange={(event) => onChangeCheckbox(event, "hold")} checked={checkboxStates.hold} />
                <label htmlFor="addtomoders7">Уровень <span>Хольд</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders8" onChange={(event) => onChangeCheckbox(event, "foring")} checked={checkboxStates.foring} />
                <label htmlFor="addtomoders8">Уровень <span>Форинг</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders8" onChange={(event) => onChangeCheckbox(event, "yarl")} checked={checkboxStates.yarl} />
                <label htmlFor="addtomoders8">Уровень <span>Ярл</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders8" onChange={(event) => onChangeCheckbox(event, "konun")} checked={checkboxStates.konun} />
                <label htmlFor="addtomoders8">Уровень <span>Конун</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders9" onChange={(event) => onChangeCheckbox(event, "top100")} checked={checkboxStates.top100} />
                <label htmlFor="addtomoders9">Топ 100 👑</label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
            </div>
        </div>
        <div className="dinamic-inner-header" style={{ fontSize: "14px" }}>
            <span>Найдено по фильтру</span><span>{filteredUsers.length}</span>
        </div>
    </>
}