import { User } from "../../../types";
import { useLevels } from "../../../context/LevelsContext";
import { formatNumber, sliceUsername } from "../../../utils";

interface PlayerListProps {
    label: "Мои" | "Топ";
    users: User[];
}

export default function PlayerList({ label, users }: PlayerListProps) {
    const { levels } = useLevels();

    if(!levels) return null;

    return <div className="inner-part-of-modal">
        <div className="inner-list-players">
            {users.map((user, index) => {
                const level = levels.find(level => level.key === user.levelKey);

                if (!level) return null;

                return <div className="player-stroke" key={user.id}>
                    <div className="left-part-player">

                        <div className="all-info-player">
                            <div className="name-of-player"><span>#{index + 1}</span><div className="name-item">{sliceUsername(user.tg_firstname + (user.tg_lastname ? ` ${user.tg_lastname}` : ""))}</div></div>
                            <div className="down-part-of-info">
                                <div className="info-value">{level.level_name}</div>
                                <div className="info-value -second-style">Игроков <span>{user.friends.length}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="right-part-player">
                        {label === "Мои" && <div className="my-coins-proc">+{formatNumber(user.bonusMushrooms ?? user.mushrooms * 0.1)}</div>}

                        <span>{formatNumber(user.mushrooms)}</span>
                        <div className="coin-icon -w18"></div>
                    </div>
                </div>
            })}
        </div>
    </div>
}