import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Layout() {
    return <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Duck Valhalla</title>
            <meta name="viewport" content="width=device-width" />
            <link href="/assets/user.css" rel="stylesheet" />
        </Helmet>
        <Outlet />
    </HelmetProvider>

}
