import Error from "../Error";
import Body from "./Body";
import { useError } from "../../../context/ErrorContext";

export default function Container() {
    const { error } = useError();

    if (error) return <Error>{error}</Error>;

    return <div className="body">
        <Body />
    </div>
}

