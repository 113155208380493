import http from "./index";

const createMessage = async (data: { type: "mass" | "zakrep", image: File, link: string, message: string, title: string }): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append("banner", data.image);
        formData.append("link", data.link);
        formData.append("message", data.message);
        formData.append("type", data.type);
        formData.append("title", data.title);

        const response = await http.post(`/admin/messages/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

const MessagesContoller = {
    createMessage
}

export default MessagesContoller;