import { useEffect, useState } from "react"
import { Task } from "../../../types"
import { useTasks } from "../../../context/TasksContext";
import $ from "jquery";
import { useTabs } from "../../../context/TabsContext";
import { useUser } from "../../../context/UserContext";
import { copyToClipboard } from "../../../utils";
import TgShareButton from "../TgShareButton";
import with_agaric from "../../../assets/img/ducks/with-agaric.png";
import duck_ref from "../../../assets/img/ducks/duck-ref.png";
import arrow from "../../../assets/img/icons/arrow.svg";

export default function AddFriends() {
    const { tasks } = useTasks();
    const { setActiveTab } = useTabs();
    const { user } = useUser();
    const [task, setTask] = useState<Task | undefined>(undefined);
    const [copyState, setCopyState] = useState("copy");

    useEffect(() => {
        if (tasks && user) {
            setTask(tasks.find(task => task.key === "invite"))
        }
    }, [tasks, user])

    if (!task || !user) return null;

    const closeModal = () => $('.modal-window.-add-a-friends').removeClass('-open');

    return <div className={`modal-window -task -add-a-friends invite-modal`}>
        <div className="close-modal -svg" onClick={closeModal}>
            <svg id="Capa_1" enableBackground="new 0 0 320.591 320.591" viewBox="0 0 320.591 320.591" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg>
        </div>
        <div className="up-part-of-modal">
            <div className="left-part">
                <div className="alert-of-modal">Задание</div>
                <div className="modal-header">
                    Пригласи {task.quantity} людей<br></br>
                    и получи бонусные вознаграждения!
                </div>
                <div className="will-added">
                    <span>+</span> {task.rewardMushrooms}
                    <div className="coin-icon -w24"></div>
                    <span>+</span> {task.rewardTickets}
                    <div className="ticket-icon -w24"></div>
                </div>
            </div>
            <div className="right-part">
                <img src={with_agaric} alt="" />
            </div>

        </div>
        <div className="inner-part-of-modal">
            <div className="main-signature -modal-mb20">
                Выполни условия задания и получи бонусные мухоморы и игровые билеты!
            </div>
            <div className="ref-link-wrap -modal-mb20">
                <div className="signature-for-ref-link">Твоя реферальная ссылка:</div>
                <div className="ref-link">
                    <div className="ref-link-left">
                        <div className="icon-div">
                            <img src={duck_ref} alt="" />
                        </div>
                        <div className="txt-link">
                            {process.env.REACT_APP_REF_LINK! + user.tg_id}
                        </div>
                    </div>

                    <div className="copy-link" onClick={() => {
                        copyToClipboard(process.env.REACT_APP_REF_LINK! + user.tg_id);
                        setCopyState("copied");
                        setTimeout(() => setCopyState("copy"), 5000);
                    }}>
                        <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 469.34">
                            <path className="cls-1" d="M128,42.67C128,19.1,147.1,0,170.67,0h256c23.56,0,42.67,19.1,42.67,42.67v256c0,23.56-19.1,42.67-42.67,42.67h-21.33c-11.78,0-21.33-9.55-21.33-21.33v-160c0-41.24-33.43-74.67-74.67-74.67h-160c-11.78,0-21.33-9.55-21.33-21.33v-21.33ZM0,170.67c0-23.56,19.1-42.67,42.67-42.67h256c23.56,0,42.67,19.1,42.67,42.67v256c0,23.56-19.1,42.67-42.67,42.67H42.67c-23.56,0-42.67-19.1-42.67-42.67v-256Z"></path>
                        </svg>
                        <div className="signature">
                            {copyState}
                        </div>
                    </div>
                </div>
                <div className="signature-for-ref-link">Всего привлечено игроков: <span>{user.friends.length}</span></div>
            </div>
            <div className="main-signature">
                С каждого приведенного игрока, который сыграет минимум 1 раз, ты получаешь игровой <span>билет</span> и <span>10%</span> от всех собранных им мухоморов на свой баланс.
            </div>

        </div>
        <div className="down-part-of-modal">
            <TgShareButton />

            <div className="text-btn -second-style" onClick={() => setActiveTab('players')}>
                Статистика игроков <span><img src={arrow} alt="" /></span>
            </div>
        </div>
    </div>
}

