import $ from "jquery";

export default function Invite() {
    return <div className="block-info -fdc ">
        <div className="left-part">
            <div className="main-txt -fz14">
                Пригласи игроков!
            </div>
        </div>

        <button className="btn-action -open-add-a-friends" onClick={() => { $('.modal-window.-add-a-friends').addClass('-open') }}>
            <div className="signature-btn-action">
                Пригласить
            </div>
            <div className="will-added value-btn-action">
                +
                <div className="ticket-icon -w14"></div>
            </div>
            <div className="will-added value-btn-action">
                + 10%
                <div className="coin-icon -w14"></div>
            </div>
        </button>
    </div>;
}