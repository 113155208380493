import { useTabs } from "../../../../context/TabsContext";
import { Task } from "../../../../types";
import Status from "./layout/Status";

interface Props {
    task: Task;
}

export default function Farm({ task }: Props) {
    const { setActiveTab } = useTabs();

    return <div className={`block-info -fdc -play-game ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m479.001 34.656-193.068-34.043c-21.715-3.829-42.423 10.671-46.252 32.386l-1.827 10.363c27.921 3.44 51.51 23.554 58.937 51.269l11.799 44.035c6.247-2.709 13.329-3.685 20.537-2.414 13.677 2.412 24.17 12.255 28.079 24.642 7.91-10.303 21.137-15.964 34.814-13.552 19.575 3.452 32.646 22.119 29.194 41.694-7.5 42.534-53.143 76.721-74.804 90.782l33.071 123.423c1.773 6.616 2.512 13.266 2.349 19.79l27.824 4.906c21.715 3.829 42.423-10.671 46.252-32.386l55.48-314.641c3.83-21.717-10.67-42.425-32.385-46.254z" /><path d="m267.867 102.382c-4.78-17.838-20.911-29.603-38.54-29.602-3.42 0-6.898.443-10.359 1.37l-189.366 50.74c-21.299 5.707-33.939 27.6-28.232 48.899l82.691 308.609c4.78 17.838 20.911 29.602 38.54 29.602 3.42 0 6.898-.443 10.358-1.37l189.366-50.741c21.299-5.707 33.939-27.6 28.232-48.899zm-147.357 210.951c-3.211-2.825-4.41-7.302-3.042-11.353l28.956-85.738c2.422-7.172 11.364-9.568 17.048-4.568l67.946 59.774c3.211 2.825 4.41 7.302 3.042 11.353l-28.956 85.738c-2.422 7.172-11.364 9.569-17.048 4.568z" /></svg>
            </div>

            <div className="main-txt -fz14">
                Собери <span>{task.quantity}</span> мухоморов
            </div>
        </div>

        <div className="decor-icon -w100proc">
            <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m479.001 34.656-193.068-34.043c-21.715-3.829-42.423 10.671-46.252 32.386l-1.827 10.363c27.921 3.44 51.51 23.554 58.937 51.269l11.799 44.035c6.247-2.709 13.329-3.685 20.537-2.414 13.677 2.412 24.17 12.255 28.079 24.642 7.91-10.303 21.137-15.964 34.814-13.552 19.575 3.452 32.646 22.119 29.194 41.694-7.5 42.534-53.143 76.721-74.804 90.782l33.071 123.423c1.773 6.616 2.512 13.266 2.349 19.79l27.824 4.906c21.715 3.829 42.423-10.671 46.252-32.386l55.48-314.641c3.83-21.717-10.67-42.425-32.385-46.254z" /><path d="m267.867 102.382c-4.78-17.838-20.911-29.603-38.54-29.602-3.42 0-6.898.443-10.359 1.37l-189.366 50.74c-21.299 5.707-33.939 27.6-28.232 48.899l82.691 308.609c4.78 17.838 20.911 29.602 38.54 29.602 3.42 0 6.898-.443 10.358-1.37l189.366-50.741c21.299-5.707 33.939-27.6 28.232-48.899zm-147.357 210.951c-3.211-2.825-4.41-7.302-3.042-11.353l28.956-85.738c2.422-7.172 11.364-9.568 17.048-4.568l67.946 59.774c3.211 2.825 4.41 7.302 3.042 11.353l-28.956 85.738c-2.422 7.172-11.364 9.569-17.048 4.568z" /></svg>
        </div>

        <Status reward={{ mushrooms: task.rewardMushrooms, tickets: task.rewardTickets }} onClick={() => setActiveTab("ducks")} title="Играть"/>
    </div>
}