import { Navigate, useLocation } from "react-router-dom";
import { useAdmin } from "../context/AdminContext";
import Container from "./admin/container/Container";

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const auth = useAdmin();
    const location = useLocation();

    if (!auth.admin) {
        return <Navigate to="/admp/login" state={{ from: location }} replace />;
    }

    return <Container>{children}</Container>;
}