import { useEffect, useState } from "react";
import { Task, User } from "../../types";
import TasksContoller from "../../api/admin/tasks";
import { toast } from "react-toastify";
import Mystery from "../../components/admin/tasks/Mystery";
import UsersContoller from "../../api/admin/users";
import { Link } from "react-router-dom";
import Sub from "../../components/admin/tasks/Sub";
import FarmInviteVisitGroup from "../../components/admin/tasks/FarmInviteVisitGroup";

export default function Tasks() {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const getTasks = async () => {
        const response = await TasksContoller.getTasks();

        if (response.error) return toast(response.message);

        setTasks(response.tasks);
    }

    const getUsers = async () => {
        const response = await UsersContoller.getUsers();

        if (response.error) return toast(response.message);

        setUsers(response.users);
    }

    useEffect(() => {
        getTasks();
        getUsers();
    }, []);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Задания
            </div>

            <div className="dinamic-content -cmr-users -other-pages-content">
                {/* <div className="dinamic-inner-header">
                    <span>Найдено</span><span>4</span>
                </div> */}

                <div className="filtration-wrap">
                    {/* <div className="search-part">
                        <div className="input-wrap">
                            <input type="text" placeholder="Название задания" />
                            <div className="error-input">
                                Ничего не найдено
                            </div>
                        </div>
                        <button className="search-btn">
                            <div className="icon-div search"></div>
                        </button>
                    </div>
                    <div className="filter-checkbox">
                        <div className="checkbox-item -crm-checkbox-item">
                            <input type="checkbox" id="addtomoders" />
                            <label htmlFor="addtomoders">Завершено <span className="-yellow-info">0</span> </label>
                        </div>

                        <div className="checkbox-item -crm-checkbox-item">
                        </div>
                    </div> */}
                    <div className="filter-checkbox -tasks-action">
                        <Link to="/admp/tasks/create/mystery" className="btn-s-gradient">
                            <span>Изменить еженедельный шифр викингов</span>
                        </Link>
                        <Link to="/admp/tasks/create/sub" className="btn-s-gradient">
                            <span>+ Добавить задания с подпиской на паблик</span>
                        </Link>
                        <Link to="/admp/tasks/change/farminvitevisit/invite" className="btn-s-gradient">
                            <span>Настройки реферальных заданий</span>
                        </Link>
                        <Link to="/admp/tasks/change/farminvitevisit/visit" className="btn-s-gradient">
                            <span>Настройки заданий с заходами в бота</span>
                        </Link>
                        <Link to="/admp/tasks/change/farminvitevisit/farm" className="btn-s-gradient">
                            <span>Настройки заданий с фармом игре</span>
                        </Link>
                        <Link to="/admp/tasks/inactivesubs/" className="btn-s-gradient">
                            <span>Завершенные задания</span>
                        </Link>
                    </div>
                </div>

                <div className="list-of-profits">
                    {tasks.filter(task => task.key === "mystery" && task.active).map(task => {
                        return <Mystery key={task.uuid} task={task} users={users} setTasks={setTasks} />
                    })}

                    {tasks.filter(task => task.key === "sub" && task.active).map(task => {
                        return <Sub key={task.uuid} task={task} users={users} setTasks={setTasks} />
                    })}

                    <FarmInviteVisitGroup type="invite" tasks={tasks} users={users} />

                    <FarmInviteVisitGroup type="farm" tasks={tasks} users={users} />

                    <FarmInviteVisitGroup type="visit" tasks={tasks} users={users} />

                    <div className="profit-item -empty-item"></div>
                    <div className="profit-item -empty-item"></div>
                    <div className="profit-item -empty-item"></div>
                </div>
            </div>
        </div>
    </section>
}