import Message from "../../components/admin/messages/Message";

export default function Messages() {
    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="dinamic-inner-header mb40">
                <span>⚠️ ВНИМАНИЕ! Сообщение с бота нельзя будет удалить у игроков.</span>
            </div>

            <Message type="mass" />

            <Message type="zakrep" />
        </div>
    </section>
}