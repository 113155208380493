import { ToastContainer } from "react-toastify";
import Nav from "./Nav";
import 'react-toastify/dist/ReactToastify.css';

export default function Container(props: React.PropsWithChildren<{}>) {
    return <div id="crm-tamplate">
        <Nav />

        {props.children}

        <ToastContainer theme="dark" />
    </div>
}