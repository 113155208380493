import duck_head from "../../assets/img/duck-head.png";
import { useUser } from "../../context/UserContext";

export default function Preloader() {
    const { user } = useUser();

    if(!user) return null;

    return <div className="prelouder">
        <div className="preloader-inner">
            <div className="image-wrap">
                <img src={duck_head} alt="" />
            </div>
            <div className="load-wrap">
                <div className="load-line"></div>
            </div>
            <div className="signature-for-loader">
                Загрузка...
            </div>
        </div>
        <div className="days-check">
            <div className="header-of-days-check">
                Зашел в игру<br />дней подряд:
            </div>
            <div className="days">
                {user.visitStrike}
            </div>
        </div>
    </div>
}