import Header from "./Header";
import { usePreventCollapse } from "../../../hooks/usePreventCollapse";
import $ from "jquery";
import { useUser } from "../../../context/UserContext";

export default function Players() {
    const { user } = useUser();
    usePreventCollapse();

    if(!user) return null;

    return <div className="up-of-inner-main-part -ref-link">
        <Header />

        <div className="up-of-inner-main-part -game-page -players-page">

            <div className="block-info -fdc ">
                <div className="left-part">
                    <div className="main-txt -fz14">
                        Топ <span>100</span> игроков
                    </div>
                </div>

                <button className="btn-action -open-top-players" onClick={() => $('.modal-window.-top-players').addClass('-open')}>
                    <div className="signature-btn-action">
                        Просмотреть
                    </div>
                </button>
            </div>
            <div className="block-info -fdc my-players">
                <div className="left-part">
                    <div className="main-txt -fz14">
                        Мои игроки: <span>{user.friends.length}</span>
                    </div>
                </div>
                <button className="btn-action -open-list-my-players" onClick={() => $('.modal-window.-list-my-players').addClass('-open')}>
                    <div className="signature-btn-action">
                        Просмотреть
                    </div>
                </button>
            </div>

        </div>
    </div>
}