import { useTabs } from "../../../context/TabsContext";
import { TabsType } from "../../../types";
import Ducks from "../ducks/Ducks";
import Secret from "../modal/Secret";
import Players from "../players/Players";
import Tasks from "../tasks/Tasks";

export default function ActiveTab() {
    const { activeTab } = useTabs();

    const renderTab = (tab: TabsType) => {
        switch (tab) {
            case "ducks":
                return <Ducks />;
            case 'tasks':
                return <Tasks />;
            case 'players':
                return <Players />;
            case 'secret':
                return <Secret />;
            default:
                return null
        }
    }

    return renderTab(activeTab);
}