const values = [
    { value: '2', coins: 2 },
    { value: '3', coins: 3 },
    { value: '4', coins: 4 },
    { value: '5', coins: 5 },
    { value: '6', coins: 6 },
    { value: '7', coins: 7 },
    { value: '8', coins: 8 },
    { value: '9', coins: 9 },
    { value: '10', coins: 10 },
    { value: 'ace', coins: 14 },
    { value: 'jack', coins: 22 },
    { value: 'queen', coins: 17 },
    { value: 'king', coins: 27 }
];


export const cardArray = shuffle(['club', 'heart', 'spade', 'diamond'].flatMap(suit =>
    values.map(({ value, coins }) => ({
        type: suit,
        value: value,
        coins: coins
    }))
));

// export const cardArray = [
//     { type: 'club', value: '2', coins: 2 },
//     { type: 'club', value: '3', coins: 3 },
//     { type: 'club', value: '4', coins: 4 },
//     { type: 'club', value: '5', coins: 5 },
//     { type: 'club', value: '6', coins: 6 },
//     { type: 'club', value: '7', coins: 7 },
//     { type: 'club', value: '8', coins: 8 },
//     { type: 'club', value: '9', coins: 9 },
//     { type: 'club', value: '10', coins: 10 },
//     { type: 'club', value: 'ace', coins: 14 },
//     { type: 'club', value: 'jack', coins: 22 },
//     { type: 'club', value: 'queen', coins: 17 },
//     { type: 'club', value: 'king', coins: 27 },
//     { type: 'heart', value: '2', coins: 2 },
//     { type: 'heart', value: '3', coins: 3 },
//     { type: 'heart', value: '4', coins: 4 },
//     { type: 'heart', value: '5', coins: 5 },
//     { type: 'heart', value: '6', coins: 6 },
//     { type: 'heart', value: '7', coins: 7 },
//     { type: 'heart', value: '8', coins: 8 },
//     { type: 'heart', value: '9', coins: 9 },
//     { type: 'heart', value: '10', coins: 10 },
//     { type: 'heart', value: 'ace', coins: 14 },
//     { type: 'heart', value: 'jack', coins: 22 },
//     { type: 'heart', value: 'queen', coins: 17 },
//     { type: 'heart', value: 'king', coins: 27 },
//     { type: 'spade', value: '2', coins: 2 },
//     { type: 'spade', value: '3', coins: 3 },
//     { type: 'spade', value: '4', coins: 4 },
//     { type: 'spade', value: '5', coins: 5 },
//     { type: 'spade', value: '6', coins: 6 },
//     { type: 'spade', value: '7', coins: 7 },
//     { type: 'spade', value: '8', coins: 8 },
//     { type: 'spade', value: '9', coins: 9 },
//     { type: 'spade', value: '10', coins: 10 },
//     { type: 'spade', value: 'ace', coins: 14 },
//     { type: 'spade', value: 'jack', coins: 22 },
//     { type: 'spade', value: 'queen', coins: 17 },
//     { type: 'spade', value: 'king', coins: 27 },
//     { type: 'diamond', value: '2', coins: 2 },
//     { type: 'diamond', value: '3', coins: 3 },
//     { type: 'diamond', value: '4', coins: 4 },
//     { type: 'diamond', value: '5', coins: 5 },
//     { type: 'diamond', value: '6', coins: 6 },
//     { type: 'diamond', value: '7', coins: 7 },
//     { type: 'diamond', value: '8', coins: 8 },
//     { type: 'diamond', value: '9', coins: 9 },
//     { type: 'diamond', value: '10', coins: 10 },
//     { type: 'diamond', value: 'ace', coins: 14 },
//     { type: 'diamond', value: 'jack', coins: 22 },
//     { type: 'diamond', value: 'queen', coins: 17 },
//     { type: 'diamond', value: 'king', coins: 27 },
// ];

export const copyToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(text);
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }
}

export function shuffle(array: any[]) {
    var m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
};

export function getUserLevel(user_level: number, balance: number) {
    if (user_level !== 1) return "";

    if (balance < 0) {
        throw new Error("Balance cannot be negative");
    }

    const maxBalance = 1000000;

    // Определить диапазон одного уровня
    const levelRange = maxBalance / 10;

    // Рассчитать уровень
    let level = Math.floor(balance / levelRange) + 1;

    // Убедиться, что уровень не превышает 10
    if (level > 10) {
        level = 10;
    }

    return "Ур." + level;
}

const rewardForCombination = {
    double: 40,
    straight: 100,
    flush: 160,
    straight_flush: 500,
    royal_flush: 500,
}

const combinationNames = {
    double: "пара",
    straight: "стрит",
    flush: "флэш",
    straight_flush: "стрит флэш",
    royal_flush: "флэш рояль",
}

export interface CardType {
    type: "heart" | "club" | "spade" | "diamond",
    value: "7" | "8" | "9" | "10" | "jack" | "queen" | "king" | "ace" | "2" | "3" | "4" | "5" | "6",
}

const combinations: { [key: string]: CardType[] } = {
    straight: [{ type: "heart", value: "7" }, { type: "club", value: "8" }, { type: "diamond", value: "9" }, { type: "spade", value: "10" }, { type: "club", value: "jack" }],
    flush: [{ type: "club", value: "ace" }, { type: "club", value: "jack" }, { type: "club", value: "7" }, { type: "club", value: "4" }, { type: "club", value: "2" }],
    straight_flush: [{ type: "heart", value: "7" }, { type: "heart", value: "8" }, { type: "heart", value: "9" }, { type: "heart", value: "10" }, { type: "heart", value: "jack" }],
    royal_flush: [{ type: "spade", value: "10" }, { type: "spade", value: "jack" }, { type: "spade", value: "queen" }, { type: "spade", value: "king" }, { type: "spade", value: "ace" }],
}

export interface ComboInfo {
    name: "пара" | "стрит" | "флэш" | "стрит флэш" | "флэш рояль",
    reward: 40 | 100 | 160 | 500 | 500,
}

const deepEqual = (a: CardType[], b: CardType[]) => {
    if (a.length !== b.length) return false;
    return a.every((value, index) => value.type === b[index].type && value.value === b[index].value);
}

export const checkCombination = (cards: CardType[], multiplier: number) => {
    for (const [key, combination] of Object.entries(combinations)) {
        if (deepEqual(cards, combination)) {
            return {
                name: combinationNames[key as keyof typeof combinationNames] as ComboInfo["name"],
                reward: rewardForCombination[key as keyof typeof rewardForCombination] as ComboInfo["reward"] * multiplier
            }
        }
    }

    if (cards.length >= 2) {
        const lastCard = cards[cards.length - 1];
        const secondLastCard = cards[cards.length - 2];

        if (lastCard.value === secondLastCard.value) {
            return {
                name: combinationNames.double as ComboInfo["name"],
                reward: rewardForCombination.double as ComboInfo["reward"] * multiplier
            }
        }
    }

    return null;
}

export function formatTime(ms: number) {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    // const seconds = String(totalSeconds % 60).padStart(2, '0');

    return `${hours}ч:${minutes}м`;
}

export function formatGameTimer(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatDate(date: Date) {
    return new Date(date).toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    })
}

export const sliceUsername = (name: string) => {
    if (name.length > 15) return name.slice(0, 15) + "...";
    return name;
}

export const levelClassNames = ["", "-bronze-deck", "-gold-deck", "-platina-deck", "-cosmo-deck"]

export function formatNumber(value: number) {
    if (value < 10000) {
        return Math.round(value).toString(); // Если число меньше 1000, просто возвращаем его return
    } else if (value >= 10000 && value < 1000000) {
        // Для чисел от 1000 до 999999
        const thousands = value / 1000;
        const rounded = Math.round(thousands);
        return `${rounded}к`;
    } else {
        // Для чисел от 1000000 и выше
        const millions = value / 1000000;
        const rounded = Math.round(millions * 100) / 100; // Округление до двух знаков после запятой
        return `${rounded}кк`;
    }
}