import { createContext, useContext, useEffect, useState } from "react";
import { TabsContextType, TabsType } from "../types";
import $ from "jquery";
import { useUser } from "./UserContext";

export const TabsContext = createContext<TabsContextType | undefined>(undefined);

export function TabsProvider({ children }: { children: React.ReactNode }) {
    const [activeTab, setActiveTab] = useState<TabsType>("ducks");
    const { user } = useUser();

    useEffect(() => {
        if (!user) return;

        if (activeTab === "secret") {
            $('.inner-app-part').attr('class', 'inner-app-part secret-base');
        }

        if (activeTab === "ducks") {
            $('.inner-app-part').attr('class', `inner-app-part start-display -duck-level${user.level.user_level}`);
        }

        if (activeTab === "tasks") {
            $('.inner-app-part').attr('class', 'inner-app-part tasks-display');
        }

        if (activeTab === "players") {
            $('.inner-app-part').attr('class', 'inner-app-part players-display');
        }
    }, [user, activeTab]);

    return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>;
}

export const useTabs = () => {
    const context = useContext(TabsContext);

    if (!context) {
        throw new Error("useTabs must be used within a GameProvider");
    }
    return context;
};