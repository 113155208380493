import { useEffect, useState } from "react";
import SettingsController from "../../api/admin/settings";
import { toast } from "react-toastify";
import SettingGameForm from "../../components/admin/settings/SettingsGameForm";

export default function SettingsGame() {
    const [dropChance, setDropChance] = useState("");
    const [bombChance, setBombChance] = useState("");
    const [decreaseMushroomsWhenBomb, setDecreaseMushroomsWhenBomb] = useState("");
    const [additionalTimeChance, setAdditionalTimeChance] = useState("");
    const [additionalTime, setAdditionalTime] = useState("");

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return toast(response.message);

        setDropChance(response.settings.dropChance?.toString() ?? "");
        setBombChance(response.settings.bombChance?.toString() ?? "");
        setDecreaseMushroomsWhenBomb(response.settings.decreaseMushroomsWhenBomb?.toString() ?? "");
        setAdditionalTimeChance(response.settings.additionalTimeChance?.toString() ?? "");
        setAdditionalTime(response.settings.additionalTime?.toString() ?? "");
    };

    const updateGameSettings = async (key: string, value: string) => {
        const response = await SettingsController.updateGameSettings(key, value);

        return toast(response.message);
    };

    const handleSettingUpdate = async (key: string, value: string): Promise<void> => {
        await updateGameSettings(key, value);
    };

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <section className="main-part-dinamic">
            <div className="wrap-of-dinamic">
                <div className="dinamic-inner-header mb40">
                    <div>
                        <div>⚠️ ВНИМАНИЕ! Лучше всего вносить изменения вне пика активности.</div>
                        <br />
                    </div>
                </div>
                <div className="main-header-of-page">
                    Настройки игры
                </div>

                <SettingGameForm
                    title="Общий шанс падения игровых предметов из порталов (%):"
                    state={dropChance}
                    setState={setDropChance}
                    onSubmit={handleSettingUpdate}
                    settingKey="dropChance"
                />
                <SettingGameForm
                    title="Шанс падения бомбы 🧨 из портала (%):"
                    state={bombChance}
                    setState={setBombChance}
                    onSubmit={handleSettingUpdate}
                    settingKey="bombChance"
                />
                <SettingGameForm
                    title="Уменьшенать количество собранных мухоморов🍄 при попадании бомбы 🧨 в чан на (%):"
                    state={decreaseMushroomsWhenBomb}
                    setState={setDecreaseMushroomsWhenBomb}
                    onSubmit={handleSettingUpdate}
                    settingKey="decreaseMushroomsWhenBomb"
                />
                <SettingGameForm
                    title="Шанс падения доп. времени ⌛️ из портала (%):"
                    state={additionalTimeChance}
                    setState={setAdditionalTimeChance}
                    onSubmit={handleSettingUpdate}
                    settingKey="additionalTimeChance"
                />
                <SettingGameForm
                    title="Количество доп. времени ⌛️ (сек):"
                    state={additionalTime}
                    setState={setAdditionalTime}
                    onSubmit={handleSettingUpdate}
                    settingKey="additionalTime"
                />
            </div>
        </section>
    );
}
