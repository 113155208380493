import { useUser } from "../../../../context/UserContext";
import { Task } from "../../../../types";

interface Props {
    task: Task;
}

export default function Visit({ task }: Props) {
    const { user } = useUser();

    if (!user) return null;

    return <div className={`block-info -fdc -enter-game ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="_Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                    <g id="Layer_2" data-name="Layer 2">
                        <path className="cls-1" d="M21,6H1C.45,6,0,6.45,0,7c0,0,0,0,0,0v11c0,1.1.9,2,2,2h18c1.1,0,2-.9,2-2V7c0-.55-.45-1-1-1h0ZM15.71,11.04l-5.33,5.33c-.39.39-1.02.39-1.41,0h0s-2.67-2.67-2.67-2.67c-.39-.39-.38-1.03.01-1.41.39-.38,1.01-.38,1.4,0l1.96,1.96,4.63-4.63c.39-.39,1.03-.38,1.41.01.38.39.38,1.01,0,1.4Z"></path>
                        <path className="cls-1" d="M21,8H1C.45,8,0,7.55,0,7c0,0,0,0,0,0V3C0,1.9.9,1,2,1h18c1.1,0,2,.9,2,2v4c0,.55-.45,1-1,1h0ZM2,6h18v-3H2v3Z"></path>
                        <path className="cls-1" d="M13.17,5c-.55,0-1-.45-1-1h0V1c0-.55.45-1,1-1s1,.45,1,1v3c0,.55-.45,1-1,1h0Z"></path>
                        <path className="cls-1" d="M8.83,5c-.55,0-1-.45-1-1h0V1c0-.55.45-1,1-1s1,.45,1,1v3c0,.55-.45,1-1,1h0Z"></path>
                        <path className="cls-1" d="M4.5,5c-.55,0-1-.45-1-1h0V1c0-.55.45-1,1-1s1,.45,1,1v3c0,.55-.45,1-1,1h0Z"></path>
                        <path className="cls-1" d="M17.5,5c-.55,0-1-.45-1-1h0V1c0-.55.45-1,1-1s1,.45,1,1v3c0,.55-.45,1-1,1h0Z"></path>
                    </g>
                </svg>
            </div>
            <div className="main-txt -fz14">
                Зайди в игру <span>{task.quantity} дней</span> подряд
            </div>
        </div>

        <div className="done-action -entered-in-game">
            <div className="signature-btn-action">
                <span>{user.visitStrike}</span> из {task.quantity}
            </div>
            <div className="will-added value-btn-action">
                + {task.rewardMushrooms}
                <div className="coin-icon -w14"></div>
            </div>
            <div className="will-added value-btn-action">
                + {task.rewardTickets}
                <div className="ticket-icon -w14"></div>
            </div>
        </div>

        <div className="done-action">
            <div className="signature-btn-action">
                Получил
            </div>
            <div className="will-added value-btn-action">
                + {task.rewardMushrooms}
                <div className="coin-icon -w14"></div>
            </div>
            <div className="will-added value-btn-action">
                + {task.rewardTickets}
                <div className="ticket-icon -w14"></div>
            </div>
        </div>
    </div>
}