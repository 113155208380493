import { Task } from "../../../../types";
import Status from "./layout/Status";
import $ from "jquery";

interface Props {
    task: Task;
}

export default function Mystery({ task }: Props) {
    return <div className={`block-info -fdc -mistery ${task.parentTaskUuid ? '-done-task' : ''}`}>
        <div className="left-part">
            <div className="icon-div">
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 396.66">
                    <path className="cls-1" d="M108.61,136.85C68.97,104.85,48.46,53.59,45.09,10.7c-.54-6.83-6.07-10.7-11.66-10.7-4.09,0-8.2,2.07-10.42,6.56C-17.28,88.04-1.2,199.98,44.5,244.66c10.78-32.43,28.31-64.64,51.14-92.86,4.21-5.2,8.54-10.18,12.97-14.95Z"></path>
                    <path className="cls-1" d="M488.99,6.56C486.77,2.07,482.66,0,478.57,0c-5.59,0-11.12,3.87-11.66,10.7-3.37,42.89-23.88,94.15-63.51,126.16,4.43,4.77,8.76,9.75,12.97,14.95,22.83,28.22,40.36,60.43,51.14,92.86,45.7-44.68,61.78-156.63,21.49-238.1h0Z"></path>
                    <path className="cls-1" d="M322.98,112.99v171.73h123.3c-11.9-65.91-59.72-139.42-123.3-171.73h0Z"></path>
                    <path className="cls-1" d="M189.02,284.72V112.99c-63.58,32.31-111.4,105.81-123.3,171.73h123.3Z"></path>
                    <path className="cls-1" d="M450.83,315.3H61.17c-7,0-12.68,5.68-12.68,12.68v55.99c0,7,5.68,12.68,12.68,12.68h389.67c7,0,12.68-5.68,12.68-12.68v-55.99c0-7-5.68-12.68-12.68-12.68ZM94.36,369.16c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18ZM175.18,369.16c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18ZM256,369.16c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18ZM336.82,369.16c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18ZM417.64,369.16c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18Z"></path>
                    <path className="cls-1" d="M292.4,101.15c-11.79-3.19-23.95-4.93-36.4-4.93s-24.61,1.74-36.4,4.93v183.57h72.8V101.15ZM256,243.52c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18ZM256,164.96c-7.24,0-13.1-5.9-13.1-13.18s5.87-13.18,13.1-13.18,13.1,5.9,13.1,13.18-5.87,13.18-13.1,13.18Z"></path>
                </svg>
            </div>
            <div className="main-txt -fz14">
                Восполни еженедельный<br />шифр викингов
            </div>
        </div>

        <Status reward={{ mushrooms: task.rewardMushrooms, tickets: task.rewardTickets }} onClick={() => $('.modal-window.-guess-a-riddle').addClass('-open')} />
    </div>
}