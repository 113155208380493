import { useCallback, useEffect, useState } from "react";
import { User } from "../types";
import UserController from "../api/user";

export default function useTopUsers() {
    const [topUsers, setTopUsers] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);

    const getTopPlayers = useCallback(async () => {
        const response = await UserController.getTopPlayers();

        if (response.error) return setError(response.message);

        setTopUsers(response.users);
    }, [setError, setTopUsers]);

    useEffect(() => {
        getTopPlayers();
    }, [getTopPlayers]);

    return { topUsers, error, getTopPlayers };
}