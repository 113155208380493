import { ChangeEvent, ReactNode, RefObject } from "react";

interface UploadProps {
    file: File | null;
    handleFileClick: () => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    fileInputRef: RefObject<HTMLInputElement>;
    removeFile: () => void;
    children: ReactNode;
}

export default function Upload({ file, handleFileClick, handleFileChange, fileInputRef, removeFile, children }: UploadProps) {
    return <div className="left-part-right-part-of-secondary-pages">
        <div className="upload-photo-with-examle-photo-wrap">
            <div className="upload-photo-wrap ">
                <div className="data-for-photo-with-uploaded-photo">
                    <div className="uploaded-photo">
                        {file && (
                            <img
                                src={URL.createObjectURL(file)}
                                alt="Загруженное фото"
                                className="-show-with-uploaded-photo"
                                style={{ display: "block" }}
                            />
                        )}
                    </div>
                    {children}
                </div>
                {file ? <button className="btn-more-s " onClick={removeFile}>
                    <div className="icon-div delete-file"></div>
                    Удалить изображение
                </button> :
                    <button type="button" className="btn-more-s -hide-with-uploaded-photo" onClick={handleFileClick}>
                        <div className="icon-div upload-file"></div>
                        Загрузить изображение
                    </button>
                }
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    </div>
}